function slugValidation(slug) {
  if (typeof slug !== 'string')
    return {
      valid: false,
      errorConst: 'ERR_SLUG_MUST_BE_STRING',
      errorMsg: 'unexpected error',
    };
  slug = slug.trim();
  // for invalid chars regexp, use * not + because we don't want to return
  // invalid chars on an empty string
  if (!slug.match(/^[a-zA-Z0-9]*$/))
    return {
      valid: false,
      errorConst: 'ERR_SLUG_INVALID_CHARS',
      errorMsg:
        'custom url paths are limited to English alphanumeric characters only (a-z, A-Z, 0-9)',
    };
  if (slug.length < 3)
    return {
      valid: false,
      errorConst: 'ERR_SLUG_TOO_SHORT',
      errorMsg: 'custom url paths must be at least 3 characters long',
    };
  if (slug.length > 50) // DRY_35120 slug max chars
    return {
      valid: false,
      errorConst: 'ERR_SLUG_TOO_LONG',
      errorMsg: 'custom url paths are limited to at most 50 characters',
    };
  return { valid: true };
}

export { slugValidation };
