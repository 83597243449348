/** @jsxImportSource @emotion/react */
import { css as cssFn } from '@emotion/react';
import classNames from 'classnames';

const computeMargins = ({
  m, m0, mv, mh, mb, mt, mr, ml,
}) => {
  const addPxToNum = (x) => {
    return (typeof x === 'number') ? x+'px' : x
  }
  const presence = (x) => {
    // we need 0 to be truthy for margin values
    if (typeof x === 'undefined') return false
    if (x === null) return false
    if (x === false) return false
    if (typeof x === 'string' && x.trim() === '') return false
    if (typeof x === 'number') return true
    return !!x
  }
  if (!presence(m)) {
    let _mt;
    let _mr;
    let _mb;
    let _ml;
    if (presence(m0)) {
      _mt = 0;
      _mr = 0;
      _mb = 0;
      _ml = 0;
    }
    if (presence(mv)) {
      _mt = mv;
      _mb = mv;
    }
    if (presence(mh)) {
      _ml = mh;
      _mr = mh;
    }
    if (presence(mb)) _mb = mb;
    if (presence(mr)) _mr = mr;
    if (presence(mt)) _mt = mt;
    if (presence(ml)) _ml = ml;
    _mt = addPxToNum(_mt)
    _mr = addPxToNum(_ml)
    _mb = addPxToNum(_mb)
    _ml = addPxToNum(_ml)
    if (_mt || _mr || _mb || _ml) {
      return `
        ${_mt ? 'margin-top: ' + _mt + ';' : ''}
        ${_mr ? 'margin-right: ' + _mr + ';' : ''}
        ${_mb ? 'margin-bottom: ' + _mb + ';' : ''}
        ${_ml ? 'margin-left: ' + _ml + ';' : ''}
      `
    }
    return ''
  } else {
    return `margin: ${addPxToNum(m)};`
  }
}

const H = ({
  h1,
  h2,
  h3,
  h4,
  h5,
  bold,
  lh1,
  m,
  m0,
  mv,
  mh,
  mb,
  mt,
  mr,
  ml,
  children,
  css,
  ...props
}) => {
  let theCss = false;
  let margins = computeMargins({m, m0, mv, mh, mb, mt, mr, ml})
  if (bold || lh1 || margins) {
    theCss = cssFn`
      ${bold ? 'font-weight: 700;' : ''}
      ${lh1 ? 'line-height: 1;' : ''}
      ${margins}
      ${css}
    `;
  } else {
    theCss = css;
  }
  if (h1)
    return (
      <h1 css={theCss} {...props}>
        {children}
      </h1>
    );
  if (h2)
    return (
      <h2 css={theCss} {...props}>
        {children}
      </h2>
    );
  if (h3)
    return (
      <h3 css={theCss} {...props}>
        {children}
      </h3>
    );
  if (h4)
    return (
      <h4 css={theCss} {...props}>
        {children}
      </h4>
    );
  if (h5)
    return (
      <h5 css={theCss} {...props}>
        {children}
      </h5>
    );
  return (
    <h1 css={theCss} {...props}>
      {children}
    </h1>
  );
};

const P = ({
  span,
  xl,
  lg,
  md,
  sm,
  bold,
  lh1,
  m,
  m0,
  mv,
  mh,
  mb,
  mt,
  mr,
  ml,
  children,
  css,
  className,
  ...props
}) => {
  let content = children;
  if (bold) content = <strong>{children}</strong>;
  if (xl) className = classNames('body-text-xl', className);
  if (lg) className = classNames('body-text-l', className);
  if (md) className = classNames('body-text-m', className);
  if (sm) className = classNames('body-text-s', className);
  let theCss = false;
  let margins = computeMargins({m, m0, mv, mh, mb, mt, mr, ml})
  if (lh1 || margins) {
    theCss = cssFn`
      ${bold ? 'font-weight: 700;' : ''}
      ${lh1 ? 'line-height: 1;' : ''}
      ${margins}
      ${css}
    `;
  } else {
    theCss = css;
  }
  if (span)
    return (
      <span className={className} css={theCss} {...props}>
        {content}
      </span>
    );
  return (
    <p className={className} css={theCss} {...props}>
      {content}
    </p>
  );
};

export { H, P };
