/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { H, P, breakpoints } from 'component/uiLib';

const PromptsHero = () => {
  return (
    <div css={css`
      width: 100%;
      max-width: 1200px;
      box-sizing: border-box;
      padding: 0 24px;
      margin: 36px auto 0;
      & img {
        width: 100%;
        height: auto;
      }
      text-align: center;
      #hero-desktop {
        display: inline-block;
      }
      #hero-mobile {
        display: none;
      }
      ${breakpoints.mq.down.xs} {
        padding: 0;
        #hero-desktop {
          display: none;
        }
        #hero-mobile {
          display: inline-block;
        }
      }
    `}>
      <img id="hero-desktop"
          src="/shared/assets/images/hero/Hero-Image-desktop.png"
          alt="screen shots of example questions and answers shared on Grac3land"
          width="2014"
          height="879"
        />
      <img id="hero-mobile"
          src="/shared/assets/images/hero/Hero-Image-mobile.png"
          alt="screen shots of example questions and answers shared on Grac3land"
          width="720"
          height="804"
        />
    </div>
  );
};

export { PromptsHero };
