import { observer } from 'mobx-react-lite';
import { useStore } from 'store';
import { H, P, Button } from 'component/uiLib';
import { trackingEvents } from 'shared-copied/constants.mjs';
import { PageContentLimit } from 'component/uiLib/PageContentLimit';

const AuthUnsolicited = observer(() => {
  const store = useStore();
  store.useTrackedPageEvent(trackingEvents.pvUnsolicited);
  return (
    <PageContentLimit textualPage>
      <div data-testid="authunsolicited">
        <H h3 mv={36}>
          Invitation required
        </H>
        <P>
          Oops, Grac3land accounts are currently invitation-only. Your account
          does not yet have access. You must sign in using a specific link
          containing an invite code. You can sign up for our waitlist from the
          home page if you log out.
        </P>
        <P>
          <Button primary onClick={() => store.logOut()}>
            Log out
          </Button>
        </P>
      </div>
    </PageContentLimit>
  );
});

export { AuthUnsolicited };
