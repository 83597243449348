/** @jsxImportSource @emotion/react */
import { css as cssFn } from '@emotion/react';

const Card = ({ css, noPad, nom, 'data-testid': dataTestid, ...props }) => {
  return (
    <div
      css={cssFn`
        border-radius: 2px;
        background-color: var(--color-opaque-white);
        padding: ${noPad ? '0px' : '16px'};
        margin: ${nom ? '0' : '24px 0'};
        ${css}
      `}
      data-testid={dataTestid}
      {...props}
    >
      {props.children}
    </div>
  );
};

export { Card };
