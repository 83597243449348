import Linkify from 'linkify-react';

const LinkifiedUserBlurb = ({ blurb }) => {
  return (
    <Linkify
      as="span"
      options={{
        // DRY_81225 linkify options
        defaultProtocol: 'https',
        truncate: 50,
        rel: "noopener noreferrer nofollow",
        target: {
          url: "_blank"
        },
      }}
    >
      {blurb}
    </Linkify>
  );
};

export { LinkifiedUserBlurb }
