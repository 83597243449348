/** @jsxImportSource @emotion/react */
import { Button, Card, P } from 'component/uiLib';
import { BasicsFormFieldName } from 'component/uiLib/BasicsFormFieldName';
import { StyledBasicsTextField } from 'component/uiLib/StyledBasicsTextField';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { trackingEvents } from 'shared-copied/constants.mjs';
import { slugValidation } from 'shared-copied/slugValidation.mjs';
import { useStore } from 'store';

const Slug = observer(() => {
  const store = useStore();

  const [isEditingSlug, setIsEditingSlug] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const handleChangeSlug = e => {
    store.setEditingSlug(e.target.value);
  };

  const handleCancel = () => setIsEditingSlug(false);

  const isComplete = () => !!store.user.urlSlug;
  const isEmpty = () => store.editingSlug.trim().length === 0;
  const validation = slugValidation(store.editingSlug);
  const maxChars = 50; // DRY_35120 slug max chars
  const afterBasicsConfirmed = store.userIsAtOrBeyondStep('needsFirstPrompt');

  const editNotAdd = isComplete();

  let validationErrTxt = '';
  if (validation.errorConst === 'ERR_SLUG_INVALID_CHARS') {
    validationErrTxt = 'English letters and numbers only';
  }
  if (validation.errorConst === 'ERR_SLUG_TOO_LONG') {
    validationErrTxt = 'Too long';
  }
  if (validation.errorConst === 'ERR_SLUG_TOO_SHORT' && !isEmpty()) {
    validationErrTxt = 'Too short';
  }
  if (store.slugValidationError) {
    // for server-generated errors on rejected slugs
    validationErrTxt = store.slugValidationError;
  }

  const handleSaveSlug = e => {
    e.preventDefault();
    // isComplete is a function so that we dont retain a closure reference to
    // an old value of completeness
    const wasIncomplete = !isComplete();
    setIsSaving(true);
    store
      .saveUrlSlug()
      .then(() => {
        if (wasIncomplete) {
          store.trackEvent(trackingEvents.caProfileBasicsSlug);
        } else {
          store.trackEvent(trackingEvents.edProfileBasicsSlug);
        }
        setIsEditingSlug(false);
        setIsSaving(false);
      })
      .catch(() => {
        setIsSaving(false);
      });
  };

  const slugStatic = (
    <div>
      <BasicsFormFieldName
        field="slug"
        checkMark={isComplete() && !afterBasicsConfirmed}
      />
      {store.userHomepageUrl ? (
        <P md mb={0} style={{wordWrap:'anywhere'}}>
          <a
            target="_blank"
            rel="noreferrer"
            data-testid="slugEditorLiveLink"
            className="novisited"
            href={store.userHomepageUrl}
          >
            {store.userHomepageUrl}
          </a>
        </P>
      ) : (
        store.user.urlSlug && (
          <P mb={0} md>
            <span data-testid="slugEditorNotLiveYetLink">
              {store.userHomepageUrlForSlug(store.user.urlSlug)}
            </span>
          </P>
        )
      )}
      <Button
        primary={!editNotAdd}
        secondary={editNotAdd}
        onClick={() => {
          setIsEditingSlug(true);
        }}
        data-testid="slugEditButton"
        style={{ marginTop: '16px' }}
      >
        {editNotAdd ? 'Edit' : 'Add'}
      </Button>
    </div>
  );

  const slugEditing = (
    <div>
      <BasicsFormFieldName field="slug" checkMark={false} />
      <P md>
        This will be the link to your Grac3land page. You can post it to your
        social media profiles or share it with your friends and family however
        you like.
      </P>
      <form onSubmit={handleSaveSlug}>
        <StyledBasicsTextField
          field="slug"
          value={store.editingSlug}
          onChange={handleChangeSlug}
          maxChars={maxChars}
          data-testid="urlSlugInput"
          validationErrTxt={validationErrTxt}
          isEditingAfterCompletion={isComplete()}
          placeholder={store.suggestedSlug}
        />
        <Button
          primary
          type="submit"
          data-testid="slugSaveButton"
          disabled={!validation.valid || isEmpty()}
          style={{ margin: '16px 10px 0 0' }}
          loading={isSaving}
        >
          Save
        </Button>
        <Button secondary data-testid="slugCancelButton" onClick={handleCancel}>
          Cancel
        </Button>
      </form>
    </div>
  );

  return (
    <Card data-testid="slug-card">
      {isEditingSlug ? slugEditing : slugStatic}
    </Card>
  );
});

export { Slug };
