/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { H, P, Button, Card, Icon, breakpoints } from 'component/uiLib';
import CircularProgress from '@mui/material/CircularProgress';

const StyledLinkPreview = ({
  loading,
  failed,
  url,
  title,
  host,
  imageUrl,
  nom,
  disableBreakpoints,
}) => {
  const ellipsis = (s, len) => (s.length > len ? s.substr(0, len) + '...' : s);

  const mkLink = (url, text) => (
    <a href={url} rel="nofollow noreferrer" target="_blank">
      {text ? text : ellipsis(url, 100)}
    </a>
  );

  return (
    <Card noPad nom={nom} css={css`border: 1px solid var(--color-border-light)`}>
      <div
        css={css`
          display: grid;
          grid-template-areas:
            'thumb host fade'
            'thumb link fade';
          grid-template-columns: auto 1fr;
          grid-template-rows: 1fr 1fr;
          overflow: hidden;
          & > * {
            margin: 0;
          }
          & .thethumb {
            grid-area: thumb;
            margin-right: 24px;
            color: #888;
            background-color: var(--color-fill-superlight);
            width: 100px;
            height: 100px;
            position: relative;
          }
          & .thethumb img {
            object-fit: cover;
            width: 100%;
            height: 100%;
          }
          & .thethumb svg {
            font-size: 100px;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
          }
          & .thehost {
            grid-area: host;
            padding-top: 24px;
            white-space: nowrap;
            overflow: hidden;
            color: var(--color-charcoal-light);
          }
          & .thelink {
            grid-area: link;
            padding-bottom: 24px;
            white-space: nowrap;
            overflow: hidden;
          }
          & .thefade {
            grid-area: fade;
            width: 15px;
            box-shadow: -8px 0px 7px #fff;
          }
          ${breakpoints.mq.down.xs} {
            ${!disableBreakpoints && `
            grid-template-columns: auto;
            grid-template-rows: auto auto auto auto;
            grid-template-areas:
              'thumb'
              'host'
              'link';
            & .thethumb {
              margin-right: 0;
              width: auto;
              height: auto;
              text-align: center;
              line-height: 1px;
            }
            & .thethumb img {
              object-fit: contain;
              height: 100px;
            }
            & .thethumb svg {
              font-size: 100px;
              position: relative;
              left: 0;
              top: 0;
              transform: none;
            }
            & .thehost {
              white-space: normal;
              overflow: visible;
              padding: 16px;
              overflow-wrap: break-word;
              word-break: break-word;
            }
            & .thelink {
              white-space: normal;
              overflow: visible;
              padding: 0 16px 16px 16px;
              overflow-wrap: break-word;
              word-break: break-word;
            }
            & .thefade {
              display: none;
            }
            `}
          }
        `}
      >
        <div className="thethumb">
          {imageUrl ? <img src={imageUrl} alt="thumbnail" /> : <Icon.Link />}
        </div>
        <P sm className="thehost">
          {loading && 'Loading preview...'}
          {failed && 'Oops, there was an error fetching this link.'}
          {!loading && !failed && host && <span>{host}</span>}
        </P>
        <P md className="thelink">
          {!loading && !failed && <strong>{mkLink(url, title)}</strong>}
        </P>
        {/* this fade div is to make the edge of a long link fade out, since
          we can't wrap it and maintain the square aspect ratio of the
          thumbnail.  we only get one line for host/link text (on desktop). */}
        <div className="thefade"></div>
      </div>
    </Card>
  );
};

export { StyledLinkPreview };
