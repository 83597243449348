import { constants as userProfileConstants } from 'shared-copied/UserProfile/constants.mjs';
import { UserProfile } from 'shared-copied/UserProfile/index.mjs';
import { StyledAnswerField } from 'component/uiLib/StyledAnswerField';

const AnswerField = ({ isEditing, field, value, onChange }) => {
  let maxChars;
  let invalidUrl;
  let required = false;
  let placeholder = '';
  let title = false;
  if (field === 'text') {
    maxChars = userProfileConstants.MAX_CHARS.userBlurbs;
    placeholder = 'Write...';
    // title = 'Your answer';
  }
  if (field === 'url') {
    maxChars = userProfileConstants.MAX_CHARS.urls;
    placeholder = 'https://example.com/page';
    title = 'Add URL here';
    if (value.trim() !== '') {
      // DRY_96792 prompt validation logic
      const blankProfile = new UserProfile();
      blankProfile.setPromptResponse({
        id: 'promptLinkInactiveValidation',
        version: 2,
        url: value,
      });
      const validation = blankProfile.validation;
      invalidUrl =
        validation.valid === false &&
        validation.errors.promptResponses.promptLinkInactiveValidation
          .errorConst === 'ERR_PROMPT_URL_INVALID';
    }
  }
  if (!maxChars)
    throw new Error(
      `unrecognized field in AnswerField (${JSON.stringify(field)})`
    );

  // DRY_96792 prompt validation logic
  const tooLong = value.length > maxChars;
  const empty = value.trim() === '';
  let validationError = false;
  if (isEditing) {
    if (empty && required) validationError = 'veRequired';
  }
  if (tooLong) validationError = 'veTooLong';
  if (isEditing || !empty) {
    if (invalidUrl) validationError = 'veInvalidUrl';
  }

  return (
    <StyledAnswerField
      field={field}
      value={value}
      onChange={e => onChange(field, e.target.value)}
      title={title}
      placeholder={placeholder}
      maxChars={maxChars}
      validationError={validationError}
    />
  );
};

export { AnswerField };
