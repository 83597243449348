/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Button, P } from 'component/uiLib';
import { observer } from 'mobx-react-lite';
import log from 'shared-copied/log.mjs';
import { useStore } from 'store';
import { trackingEvents } from 'shared-copied/constants.mjs';
import { StyledFirebaseAuth } from 'component/Home/StyledFirebaseAuth';
import { ConfirmationModal } from 'component/uiLib/ConfirmationModal';
import { Modal } from 'component/uiLib/Modal';

const LoginGlobalOverlay = observer(() => {

  const store = useStore();

  const firebaseUiConfig = {
    signInFlow: 'popup',
    signInOptions: [
      {
        provider: store.firebaseRef.auth.EmailAuthProvider.PROVIDER_ID,
        requireDisplayName: false,
      },
    ],
    callbacks: {
      signInSuccessWithAuthResult: authResult => {
        // note that the logic to handle the new auth state is in the store, on
        // the onAuthStateChanged callback, here all we do is show a spinner
        // until the store finishes.
        // TODO: this is optimistic thinking, we show a spinner until we assume
        // the store finishes acquiring auth, but if there's an error in that
        // process, this would just spin forever. the store currently forces
        // a page reload on an error during this process, so that's a bad
        // hack.
        log.auth('signInSuccessWithAuthResult', authResult);
        return false;
      },
      signInFailure: error => {
        log.auth('signInFailure', error);
        store.cancelGlobalLoginOverlay()
        return false;
      },
    },
  };

  return (
    <>
      <ConfirmationModal
        open={store.loginErrorMode === '_code_error_'}
        title="Invalid invite code"
        noText="Ok"
        onNo={store.cancelGlobalLoginOverlay}
      >
        <div>
          <P>
            The sign-up invite code you used is invalid. Please double
            check your URL. If you copied and pasted it, please make sure you
            copied it completely.
          </P>
          <P>If you already have an account, you can just log in normally:</P>
          <Button primary loading={store.loginButtonsSpinning} onClick={e => store.launchGlobalLoginOverlay(true)}>
            Log in with existing account
          </Button>
        </div>
      </ConfirmationModal>
      <ConfirmationModal
        open={store.loginErrorMode === '_inactive_code_'}
        title="Expired invite code"
        noText="Ok"
        onNo={store.cancelGlobalLoginOverlay}
      >
        <div>
          <P>
            The sign-up invite code you used is no longer active for
            creating new accounts. If you already have an account, you can still
            log in normally:
          </P>
          <Button primary loading={store.loginButtonsSpinning} onClick={e => store.launchGlobalLoginOverlay(true)}>
            Log in with existing account
          </Button>
        </div>
      </ConfirmationModal>
      <Modal
        title={'Log in or Sign up'}
        hideTitle
        noPad
        open={store.firebaseModalOpen}
        onClose={store.cancelGlobalLoginOverlay}
      >
        <div
          css={css`
            // hack the firebase ui card to fit the modal
            .firebaseui-container {
              max-width: none;
              margin: 0;
            }
          `}
        >
          <StyledFirebaseAuth
            uiConfig={firebaseUiConfig}
            firebaseAuth={store.firebaseRef.auth()}
          />
        </div>
      </Modal>
    </>
  );
});

export { LoginGlobalOverlay };

