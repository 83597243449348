import { ConfirmationModal } from 'component/uiLib/ConfirmationModal';
import { useStore } from 'store';
import { observer } from 'mobx-react-lite';

const GeneralErrorModal = observer(() => {
  const store = useStore();
  let title = 'Oops, something went wrong.';
  let body =
    'Please try again. If the problem persists, reload the page and try one more time. Otherwise please contact support via the feedback link. Apologies!';
  if (store.generalError === '_api_fail_') {
    title = 'Network error';
    body =
      'There was a problem talking to the server. If the problem persists, please contact support.';
  }
  if (store.generalError === '_get_me_first_fail_') {
    title = 'Network error';
    body =
      'There was a problem talking to the server. You may need to reload the page. If the problem persists, please contact support.';
  }
  return (
    <ConfirmationModal
      open={!!store.generalError}
      title={title}
      body={body}
      noText={'OK'}
      onNo={store.clearGeneralError}
    />
  );
});

export { GeneralErrorModal };
