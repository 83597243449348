/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { H } from 'component/uiLib';
import { EncircledIcon } from 'component/uiLib/EncircledIcon'

const BasicsFormFieldName = ({ field, checkMark }) => {
  return (
    <div
      css={css`
        display: grid;
        grid-template-columns: auto 1fr;
        align-items: center;
      `}
    >
      {!checkMark && (
        <EncircledIcon name={field} style={{marginRight:'3px'}} />
      )}
      {checkMark && (
        <EncircledIcon data-testid="completed-check" name="check" style={{marginRight:'3px'}} />
      )}
      <H h4 bold m='0 0 0 8px'>
        {field === 'name' && 'Your name'}
        {field === 'slug' && 'Your page’s link'}
        {field === 'avatar' && 'Your avatar'}
      </H>
    </div>
  );
};

export { BasicsFormFieldName };
