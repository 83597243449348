/** @jsxImportSource @emotion/react */

import { observer } from 'mobx-react-lite';
import { useEffect, useRef, useState } from 'react';
import log from 'shared-copied/log.mjs';
import { useStore } from 'store';
import { trackingEvents } from 'shared-copied/constants.mjs';
import { StyledLogInSignUp } from 'component/LogInSignUp/StyledLogInSignUp';

const LogInSignUp = observer(() => {
  const store = useStore();

  store.useTrackedPageEvent(trackingEvents.pvHomeUnauth);

  return <StyledLogInSignUp signupCode={store.signupCode} />;
});

export { LogInSignUp };
