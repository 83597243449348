import clientAppPaths from '../../clientAppPaths';
import { observer } from 'mobx-react-lite';
import { useStore } from 'store';
import { trackingEvents } from 'shared-copied/constants.mjs';
import { StyledAppHeader } from 'component/uiLib/StyledAppHeader';

const AppHeader = observer(() => {
  const store = useStore();

  const handleLogout = e => {
    store.logOut(); // also navigates to home
  };

  const handleFeedback = e => {
    store.trackEvent(trackingEvents.bcFeedback);
    window.open(clientAppPaths.feedbackFormPath, '_blank');
  };

  const handleViewPage = () => {
    // the navigation is part of the button
    store.trackEvent(trackingEvents.bcViewPage);
  };

  return (
    <StyledAppHeader
      onFeedback={handleFeedback}
      showFeedback={store.getShouldShowFeedbackButton()}
      viewPageUrl={store.userHomepageUrl}
      onViewPage={handleViewPage}
      loggedIn={!!store.user}
      onLogout={handleLogout}
    />
  );
});

export { AppHeader };
