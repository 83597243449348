export const PRIVACY_POLICY_CONTENT = `
<h1>Privacy Notice</h1>
December 14, 2022

<p>We care about your privacy. When we collect information about you on grac3land.com, this Privacy Notice describes how we handle that information.</p>

<h3>Things you should know</h3>
<ul>
	<li><strong>User Feedback</strong>: You can provide feedback to us on our website, through an in-product experience, or through channels such as email. Please minimize the personal information you choose to share on these forums because your comments may be accessible to the public.</li>
	<li><strong>Accounts & Subscriptions</strong>: Our website requires you to create an account. When you create an account, we receive your email address and user name. You may periodically receive emails in connection with your account or through subscriptions. Our email management vendor is Google’s Gmail.</li>
	<li><strong>Functionality</strong>: We may use information such as cookies, device information, and IP addresses to enhance our website’s functionality. For example:</li>
		<ul>
			<li>We use cookies to assist with user sign-in and authentication so you can bypass re-entering your passwords.</li>
			<li>We use IP addresses to customize communications by language and country.</li>
			<li>We use certain device information such as country, language, operator and OEM, to customize your experience.</li>	
		</ul>
	<li><strong>Metrics</strong>: We may also use cookies, device information, and IP addresses, along with clear GIFs, cookies and third party services to help us understand in the aggregate how users engage with our website. Specifically, we use Amplitude, which has its own <a href="https://amplitude.com/privacy">Privacy Notice</a>.</li>
	<li><strong>Personal Data</strong>: We may also receive your name, mailing address, and/or email address. This data is used for fraud detection and record-keeping.</li>
</ul>

<h3>When do we share your information with others?</h3>
<ul>
	<li>When we have asked and received your permission to share it.</li>
	<li>For processing or providing products and services to you, but only if those entities receiving your information are contractually obligated to handle the data in ways that are approved by us.</li>
	<li>When the law requires it. We follow the law whenever we receive requests about you from a government or related to a lawsuit. We'll notify you when we're asked to hand over your personal information in this way unless we're legally prohibited from doing so. When we receive requests like this, we'll only release your personal information if we have a good faith belief that the law requires us to do so. Nothing in this policy is intended to limit any legal defenses or objections that you may have to a third party's request to disclose your information.</li>
	<li>When we believe it is necessary to prevent harm to you or someone else. We will only share your information in this way if we have a good faith belief that it is reasonably necessary to protect the rights, property or safety of you, our other users, or the public.</li>
	<li>If our organizational structure or status changes (if we undergo a restructuring, are acquired, or go bankrupt) we may pass your information to a successor or affiliate.</li>
</ul>

<h4>If you are under 13, we don’t want your personal information, and you must not provide it to us. If you are a parent and believe that your child who is under 13 has provided us with personal information, please contact us to have your child’s information removed.</h4>

<p>To exercise your Data Subject Access and deletion rights, email us at <a href="mailto:contact@grace3land.com">contact@grace3land.com</a></p>
`
