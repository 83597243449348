import {
  useMediaQuery
} from '@mui/material';

const px = {
  // minimum px for each breakpoint
  xs: 0,
  sm: 600,
  md: 900,
  lg: 1200,
  xl: 1536,
};

const mq = {
  up: {
    xs: `@media (min-width: ${px.xs}px)`,
    sm: `@media (min-width: ${px.sm}px)`,
    md: `@media (min-width: ${px.md}px)`,
    lg: `@media (min-width: ${px.lg}px)`,
    xl: `@media (min-width: ${px.xl}px)`,
    useXs: () => true,
    useSm: () => useMediaQuery(`(min-width: ${px.sm}px)`),
    useMd: () => useMediaQuery(`(min-width: ${px.md}px)`),
    useLg: () => useMediaQuery(`(min-width: ${px.lg}px)`),
    useXl: () => useMediaQuery(`(min-width: ${px.xl}px)`),
    useXs: () => useMediaQuery(`(min-width: ${px.xs}px)`),
  },
  down: {
    xs: `@media (max-width: ${px.sm - 1}px)`,
    sm: `@media (max-width: ${px.md - 1}px)`,
    md: `@media (max-width: ${px.lg - 1}px)`,
    lg: `@media (max-width: ${px.xl - 1}px)`,
    xl: `@media (min-width: 0px)`,
    useXs: () => useMediaQuery(`(max-width: ${px.sm - 1}px)`),
    useSm: () => useMediaQuery(`(max-width: ${px.md - 1}px)`),
    useMd: () => useMediaQuery(`(max-width: ${px.lg - 1}px)`),
    useLg: () => useMediaQuery(`(max-width: ${px.xl - 1}px)`),
    useXl: () => true,
  },
};

const breakpoints = {
  px,
  mq,
};

export { breakpoints };
