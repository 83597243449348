/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { breakpoints, P, Button } from 'component/uiLib';
import { observer } from 'mobx-react-lite';
import { useStore } from 'store';
import { PageContentLimit } from 'component/uiLib/PageContentLimit';
import { AdvancedImage, lazyload } from '@cloudinary/react';
import { getCloudinaryTransform } from 'shared-copied/cloudinaryConfig.mjs';
import { useState } from 'react';
import { BasicsEditor } from 'component/BasicsEditor';
import { UnmountClosed } from 'react-collapse';
import { ReactComponent as ChevronDown } from 'shared-copied/assets/images/icons/chevron_down.svg';
import { ReactComponent as ChevronUp } from 'shared-copied/assets/images/icons/chevron_up.svg';

const BasicsBar = observer(() => {
  const store = useStore();
  const [edit, setEdit] = useState(false);
  if (!store.profileIsMinimallyComplete) return false;
  return (
    <>
      <PageContentLimit mergedColumns>
        <div
          css={css`
            margin-bottom: 24px;
            display: flex;
            flex-direction: row;
            gap: 16px;
            align-items: center;
            & img {
              width: 50px;
              height: 50px;
            }
            #basics-bar-name {
              flex-grow: 0;
            }
            ${breakpoints.mq.down.sm} {
              width: 100%;
              #basics-bar-name {
                flex-grow: 1;
              }
            }
          `}
        >
          <AdvancedImage
            cldImg={getCloudinaryTransform(
              'avatar',
              store.userProfile.avatarPublicID,
              100
            )}
            plugins={[lazyload()]}
            style={{ alignSelf: 'center' }}
          />
          <P md id="basics-bar-name" style={{margin:0}}>{store.userProfile.name}</P>
          {!edit && (
            <Button
              looksLikeLink
              //style={{ fontSize: '14px' }}
              onClick={() => setEdit(true)}
            >
              Edit <ChevronDown style={{marginLeft:'5px',width:'16px',height:'16px',verticalAlign:'middle'}}/>
            </Button>
          )}
          {edit && (
            <Button
              looksLikeLink
              //style={{ fontSize: '14px' }}
              onClick={() => setEdit(false)}
            >
              Close <ChevronUp style={{marginLeft:'5px',width:'16px',height:'16px',verticalAlign:'middle'}}/>
            </Button>
          )}
        </div>
      </PageContentLimit>
      <UnmountClosed isOpened={edit}>
        <BasicsEditor isDrawer onClose={() => setEdit(false)} />
      </UnmountClosed>
    </>
  );
});

export { BasicsBar };
