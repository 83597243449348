// for fast prototyping, user profile data is stored as a json blob in the
// backend and this object provides an interface to it, and (de)serialization.
// it also validates the structure and content, so both frontend and backend
// use this code.

import { constants } from './constants.mjs';
import { parse } from './parse.mjs';
import { getValidationErrors } from './validation.mjs';

class UserProfile {
  _data = {};

  constructor(opts) {
    opts = opts || {};
    let parsed;
    if (opts.json) {
      parsed = parse({ json: opts.json });
    } else if (opts.data) {
      parsed = parse({ data: opts.data });
    }
    for (let key of constants.PROFILE_KEYS) {
      if (parsed && parsed.hasOwnProperty(key)) {
        this._data[key] = parsed[key];
      }
    }
    this.backfillDefaults();
  }

  /* {{{
   * explicit getters and setters allow us to:
   * - work with only constrained / allowed keys
   * - validate them if we want
   * - apply logic upon assignment
   * - be mobx observable friendly
   */
  get name() {
    return this._data.name;
  }

  set name(x) {
    this._data.name = x;
  }

  get avatarPublicID() {
    return this._data.avatarPublicID;
  }

  set avatarPublicID(x) {
    this._data.avatarPublicID = x;
  }

  get version() {
    return this._data.version;
  }

  set version(x) {
    this._data.version = x;
  }

  setPromptResponse(newPromptResponse) {
    // see DRY_49375 promptResponse schema for what a promptResponse looks like
    const id = newPromptResponse.id
    if (!this._data.promptResponses) this._data.promptResponses = [];
    if (this.promptResponseById(id)) {
      const idx = this._data.promptResponses.findIndex(x => x.id === id)
      this._data.promptResponses[idx] = newPromptResponse
    } else {
      this._data.promptResponses.push(newPromptResponse)
    }
  }

  deletePromptResponseById(id) {
    if (!this._data.promptResponses) this._data.promptResponses = [];
    this._data.promptResponses = this._data.promptResponses.filter(x => x.id !== id)
  }

  promptById(id) {
    return constants.ALL_PROMPTS.find(p => p.id === id)
  }

  promptResponseById(id) {
    // returns a prompt response object given a prompt id, if the user has
    // completed it.  for the object schema see DRY_49375 promptResponse schema
    // in shared/UserProfile/constant.js
    if (!this._data.promptResponses) return undefined;
    const r = this._data.promptResponses.find(x => x.id === id);
    return r ? JSON.parse(JSON.stringify(r)) : undefined;
  }

  getCompletedPromptIds() {
    const self = this;
    if (!this._data.promptResponses) return [];
    let ids = this._data.promptResponses.map(r => r.id);
    // this fn only includes prompts we are not hiding from history.
    ids = ids.filter(id => self.promptResponseById(id).visibility !== 'none')
    // sorted by date - is this right? we don't know yet.
    ids.sort(
      (a, b) =>
        self.promptResponseById(b).createdAtMillis -
        self.promptResponseById(a).createdAtMillis
    );
    return ids;
  }

  // }}} getters/setters

  backfillDefaults() {
    const defaults = constants.mkDefaultProfile();
    for (let key of constants.PROFILE_KEYS) {
      this[key] = this[key] || defaults[key];
    }
  }

  cleanupUserInput() {
    // the backend applies this transformation before saving.  this makes it
    // easy to test for the presence of data in a field so we aren't thrown off
    // by pure whitespace.
    function trimRecurse(obj) {
      if (typeof obj === 'undefined' || obj === null) return obj;
      if (typeof obj === 'string') return obj.trim();
      if (Array.isArray(obj)) return obj.map(trimRecurse);
      if (typeof obj === 'object') {
        const newObj = {};
        for (let prop in obj) {
          newObj[prop] = trimRecurse(obj[prop]);
        }
        return newObj;
      }
      return obj;
    }
    this._data = trimRecurse(this._data);
  }

  get validation() {
    return getValidationErrors({ data: this._data });
  }

  isMinimallyComplete({ urlSlug }) {
    // returns true if sufficient data is defined for the homepage to be live.
    // since the slug is not part of the profile object, urlSlug must be
    // passed in.
    return !!(
      this.name &&
      this.name.trim() &&
      this.avatarPublicID &&
      urlSlug &&
      urlSlug.trim()
    );
  }

  toJSON() {
    return JSON.stringify(this._data);
  }

  toPOJO() {
    // this is used for returning profile data in payloads on the json api.
    // could return this._data here but that letters callers access/mutate
    // internal state.
    return JSON.parse(this.toJSON());
  }
}

export { UserProfile };
