// basically a switch that shows either the authenticated app or the logged out
// landing page.

import { observer } from 'mobx-react-lite';
import { useStore } from 'store';
import { UnauthHome } from './UnauthHome';
import { AuthHome } from './AuthHome';
import { AuthUnsolicited } from './AuthUnsolicited';

const Home = observer(() => {
  // store.showAuthComponents is a top level boolean in the store that is used
  // to hide or show the authed UI while logging out is taking place, because
  // it's not a single synchronous event and things break if the app renders in
  // a partially authed state.
  const store = useStore();
  if (store.showAuthComponents) {
    if (store.user.unsolicited) {
      return <AuthUnsolicited />;
    } else {
      return <AuthHome />;
    }
  }
  else return <UnauthHome />;
});


export { Home };
