import Chat from '@mui/icons-material/Chat';
import Logout from '@mui/icons-material/Logout';
import Close from '@mui/icons-material/Close';
import Menu from '@mui/icons-material/Menu';
import Public from '@mui/icons-material/Public';
import Edit from '@mui/icons-material/Edit';
import Save from '@mui/icons-material/Save';
import CheckCircle from '@mui/icons-material/CheckCircle';
import Link from '@mui/icons-material/Link';
import Image from '@mui/icons-material/CropOriginal';
import Upload from '@mui/icons-material/Upload';
import AutoAwesome from '@mui/icons-material/AutoAwesome';
import DeleteForeverOutlined from '@mui/icons-material/DeleteForeverOutlined';

const Icon = {
  Chat,
  Logout,
  Close,
  Menu,
  Public,
  Edit,
  Save,
  CheckCircle,
  Link,
  Image,
  Upload,
  AutoAwesome,
  DeleteForeverOutlined,
};

export { Icon };
