import CircularProgress from '@mui/material/CircularProgress';

const FullPageSpinner = () => {
  return (
    <div
      style={{
        position: 'fixed',
        zIndex: '1000',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        display: 'grid',
        gridTemplateColumns: '100%',
        alignItems: 'center',
        justifyItems: 'center',
      }}
    >
      <CircularProgress />
    </div>
  );
};

export { FullPageSpinner };
