const constants = {
  CURRENT_VERSION: 2,

  PROFILE_KEYS: [
    'version',
    'name',
    'avatarPublicID',
    'promptResponses',
    // deprecated
  ],

  MAX_CHARS: {
    names: 140,
    cloudinaryAssetIds: 100,
    urls: 300,
    urlMetaProps: 1000,
    userBlurbs: 1000,
  },

  // DRY_49375 promptResponse schema
  // a prompt response, which is what is stored in the profile promptResponses
  // array, looks like:
  // {
  //   id: string for the prompt id e.g. 'promptImageOutsideMyWindow'
  //
  //   format: 'text' | 'link' | 'image'
  //
  //   text: string for text format, undef otherwise
  //
  //   url: string for link format, undef otherwise
  //
  //   urlMeta: for link format responses, this is an object
  //     {
  //       host: string e.g. 'goodreads.com',
  //       title: string e.g. 'some book title',
  //       imageUrl: string e.g. 'https://goodreads.com/img/1234',
  //     }
  //   and is otherwise undef, note it could also be undef if we failed to
  //   fetch url metadata.
  //
  //   urlCaption: optional string for link format, undef otherwise
  //
  //   cloudinaryAssetId: string for image format, undef otherwise
  //
  //   imageCaption: optional string for image format, undef otherwise
  //
  //   createdAtMillis: integer epoch milliseconds of creation
  //
  //   updatedAtMillis: integer epoch milliseconds of update
  // }

  ALL_PROMPTS: [
    // prompts are defined in code here as a list of objects
    // {
    //   id: string - for internal data use, do not change it
    //
    //   visibility: 'all' | 'history' | 'none'
    //     'all': shown everywhere
    //     'history': not shown in prompter (no new answers)
    //     'none': hide even responses from user's logged in and public pages
    //
    //   showOnPages: boolean - are completed answers rendered on user pages.
    //   set this to false to actually hide all answers for the prompt. if true
    //   this also means 
    //
    //   format: 'text'|'image'|'link' (NOW *IGNORED* AS OF PROMPTS V2)
    //
    //   questionHeading: string, the question presented to the user on the
    //   profile editor
    //
    //   answeredHeading: string or undef, the heading for the prompt response
    //   once answered, in the public page (if undef, the questionHeading is
    //   used)
    //
    // }
    //
    // regarding changing these prompts:
    //
    // - a prompt tracked by the prompt id in the user profile data. The prompt
    // text can be tweaked, and all existing prompt answers will go along with
    // that. so you should never change the essential meaning of a prompt or
    // you will invalidate the user responses.
    //
    // - likewise, to remove a prompt, do not remove it from the code - just
    // set visibility to 'history' (for most cases, when we want to stop asking
    // the prompt but still want to show old answers) or 'none' (which also
    // stops rendering old answers). but the data will persist in user profiles
    // and validation will continue to work on that data. that also means
    // setting visibility back to 'all' would resurface user answers on
    // those prompts.
    //
    {
      id: 'promptImageInactiveTest',
      visibility: 'none',
      format: 'image',
      questionHeading: 'this inactive prompt exists only for unit tests',
    },
    {
      id: 'promptLinkInactiveValidation',
      visibility: 'none',
      format: 'image',
      questionHeading: 'this inactive prompt exists only for validations',
    },
    {
      id: 'promptImageOutsideMyWindow',
      visibility: 'all',
      format: 'image',
      questionHeading: 'What’s outside your window right now?',
      answeredHeading: 'Outside my window right now',
    },
    {
      id: 'promptImageMemorableHomeCookedMeal',
      visibility: 'all',
      format: 'image',
      questionHeading: 'Share a photo of a recent memorable meal you made.',
      answeredHeading: 'A memorable meal I made',
    },
    {
      id: 'promptImageMemorableRestaurantMeal',
      visibility: 'history',
      format: 'image',
      questionHeading:
        'What’s a memorable meal you had at a restaurant?',
        answeredHeading: 'A memorable meal I had at a restaurant',
      },
    {
      id: 'promptImageIMadeThis',
      visibility: 'all',
      format: 'image',
      questionHeading: 'Show something you made, and tell us why or how you made it.',
      answeredHeading: 'Something I made',
    },
    {
      id: 'promptImageThingIUseDaily',
      visibility: 'all',
      format: 'image',
      questionHeading: 'Share a photo of something you use every day.',
      answeredHeading: 'Something I use every day',
    },
    {
      id: 'promptImageNeighborhoodPhoto',
      visibility: 'all',
      format: 'image',
      questionHeading: 'Share a photo from your neighborhood.',
      answeredHeading: 'A photo from my neighborhood',
    },
    {
      id: 'promptImageVacationPhoto',
      visibility: 'all',
      format: 'image',
      questionHeading: 'Show a place you’ve been on vacation.',
      answeredHeading: 'A place I’ve been on vacation',
    },
    {
      id: 'promptImageOutdoorsPhoto',
      visibility: 'all',
      format: 'image',
      questionHeading: 'Share a picture you’ve taken of the outdoors.',
      answeredHeading: 'A picture I took of the outdoors',
    },
    {
      id: 'promptImageCityPhoto',
      visibility: 'all',
      format: 'image',
      questionHeading: 'Share a picture you’ve taken of a city.',
      answeredHeading: 'A picture I took of a city',
    },
    {
      id: 'promptImagePlantPhoto',
      visibility: 'all',
      format: 'image',
      questionHeading: 'Share a picture you’ve taken of a plant or flower.',
      answeredHeading: 'A picture I took of a plant or flower',
    },
    {
      id: 'promptImageMakesMeHappy',
      visibility: 'all',
      format: 'image',
      questionHeading: 'Share a photo you’ve taken that makes you happy.',
      answeredHeading: 'A photo I took that makes me happy',
    },
    {
      id: 'promptLinkMakesMeHappy',
      visibility: 'all',
      format: 'link',
      questionHeading: 'Share a web page that makes you happy.',
      answeredHeading: 'A web page that makes me happy',
    },
    {
      id: 'promptLinkMakesMeConcerned',
      visibility: 'all',
      format: 'link',
      questionHeading: 'Share a web page that you can’t get off your mind.',
      answeredHeading: 'A web page I can’t get off my mind',
    },
    {
      id: 'promptLinkMakesMeThink',
      visibility: 'all',
      format: 'link',
      questionHeading: 'Share a web page that made you think.',
      answeredHeading: 'A web page that made me think',
    },
    {
      id: 'promptLinkAssumptions',
      visibility: 'all',
      format: 'link',
      questionHeading: 'Share a web page that made you question your assumptions.',
      answeredHeading: 'A web page that made me question my assumptions',
    },
    {
      id: 'promptLinkFunnySocialMediaProfile',
      visibility: 'all',
      format: 'link',
      questionHeading: 'Share a social media profile that you find funny.',
      answeredHeading: 'A funny social media profile',
    },
    {
      id: 'promptLinkInsightfulSocialMediaProfile',
      visibility: 'all',
      format: 'link',
      questionHeading: 'Share a link about someone you respect.',
      answeredHeading: 'Someone I respect',
    },
    {
      id: 'promptLinkImportantSocialMediaProfile',
      visibility: 'all',
      format: 'link',
      questionHeading: 'Share a social media profile you think is important.',
      answeredHeading: 'A social media profile I find important',
    },
    {
      id: 'promptLinkToRepresentMe',
      visibility: 'all',
      format: 'link',
      questionHeading:
        'If you had to pick just one web page to best represent you, what would it be?',
      answeredHeading: 'The one web page that best represents me',
    },
    {
      id: 'promptLinkDesertIsland',
      visibility: 'all',
      format: 'link',
      questionHeading:
        'If you could only go to one web site for a year, which would you choose?',
      answeredHeading: 'If I could only visit one web site for a year',
      },
    {
      id: 'promptLinkProudOfThis',
      visibility: 'all',
      format: 'link',
      questionHeading: 'Link to something you’re proud of.',
      answeredHeading: 'Something I’m proud of',
    },
    {
      id: 'promptLinkOrgOrGroupThatMatters',
      visibility: 'all',
      format: 'link',
      questionHeading: 'Share a link about an organization or group that matters to you.',
      answeredHeading: 'An organization or group that matters to me',
    },
    {
      id: 'promptLinkWebsiteIBuilt',
      visibility: 'all',
      format: 'link',
      questionHeading: 'Share a web site that you made.',
      answeredHeading: 'A web site that I made',
    },
    {
      id: 'promptLinkEmployer',
      visibility: 'all',
      format: 'link',
      questionHeading: 'What’s your employer’s web site?',
      answeredHeading: 'My employer’s web site',
    },
    {
      id: 'promptTextFavSong',
      visibility: 'all',
      format: 'text',
      questionHeading: 'What’s your favorite song right now?',
      answeredHeading: 'My favorite song right now',
    },
    {
      id: 'promptLinkFavSong',
      visibility: 'history',
      format: 'link',
      questionHeading: 'Link to your favorite song right now.',
      answeredHeading: 'My favorite song right now',
    },
    {
      id: 'promptTextFavArtist',
      visibility: 'all',
      format: 'text',
      questionHeading: 'Who is your favorite artist right now?',
      answeredHeading: 'My favorite artist right now',
    },
    {
      id: 'promptLinkFavArtist',
      visibility: 'history',
      format: 'link',
      questionHeading: 'Link to your favorite artist right now.',
      answeredHeading: 'My favorite artist right now',
    },
    {
      id: 'promptTextRecentDream',
      visibility: 'all',
      format: 'text',
      questionHeading: 'Share your most recent dream.',
      answeredHeading: 'A recent dream I had',
    },
    {
      id: 'promptTextThankfulFor',
      visibility: 'all',
      format: 'text',
      questionHeading: 'What is something or someone you’re thankful for, and why?',
      answeredHeading: 'Something or someone I’m thankful for',
    },
    {
      id: 'promptTextLastBookRead',
      visibility: 'all',
      format: 'text',
      questionHeading: 'What is the last book you read, and what did you think of it?',
      answeredHeading: 'The last book I read',
    },
    {
      id: 'promptLinkLastBookRead',
      visibility: 'history',
      format: 'link',
      questionHeading: 'Link to the last book you read, and tell us what you thought of it.',
      answeredHeading: 'The last book I read',
    },
    {
      id: 'promptTextBestAdvice',
      visibility: 'all',
      format: 'text',
      questionHeading: 'What’s the best advice you’ve ever been given?',
      answeredHeading: 'The best advice I’ve ever been given',
    },
    {
      id: 'promptTextWhenYoung',
      visibility: 'all',
      format: 'text',
      questionHeading: 'What do you wish you had known when you were younger?',
      answeredHeading: 'I wish I’d known sooner',
    },
    {
      id: 'promptTextRegret',
      visibility: 'all',
      format: 'text',
      questionHeading: 'What’s something you regret doing or saying?',
      answeredHeading: 'Something I regret',
    },
    {
      id: 'promptTextIWouldNeverDoThis',
      visibility: 'all',
      format: 'text',
      questionHeading: 'What’s something you would never, ever do?',
      answeredHeading: 'Something I would never do',
    },
    {
      id: 'promptTextWantToTry',
      visibility: 'all',
      format: 'text',
      questionHeading: 'What’s something you want to do or try?',
      answeredHeading: 'Something I want to do or try',
    },
    {
      id: 'promptTextFavPlace',
      visibility: 'all',
      format: 'text',
      questionHeading: 'Where’s your favorite place you’ve ever been?',
      answeredHeading: 'My favorite place',
    },
    {
      id: 'promptLinkFavPlace',
      visibility: 'history',
      format: 'link',
      questionHeading: 'Share a link about your favorite place you’ve ever been.',
      answeredHeading: 'My favorite place',
    },
    {
      id: 'promptImageFavPlace',
      visibility: 'history',
      format: 'image',
      questionHeading: 'Share a photo from your favorite place you’ve ever been.',
      answeredHeading: 'My favorite place',
    },
    {
      id: 'promptTextUnusualFactAboutMe',
      visibility: 'all',
      format: 'text',
      questionHeading:
        'Share something true about you that most people don’t know.',
      answeredHeading: 'Something true about me that most people don’t know',
      },
    {
      id: 'promptTextFibAboutMe',
      visibility: 'all',
      format: 'text',
      questionHeading: 'Tell a tall tale about yourself, the bigger the better!',
      answeredHeading: 'A tall tale about me',
    },
    {
      id: 'promptTextDinnerHistorical',
      visibility: 'all',
      format: 'text',
      questionHeading: 'If you could have dinner with any historical figure, who would it be, and why?',
      answeredHeading: 'The historical figure I’d have dinner with',
    },
    {
      id: 'promptTextSuperpower',
      visibility: 'all',
      format: 'text',
      questionHeading: 'Which superpower do you wish you had, and why?',
      answeredHeading: 'The superpower I’d choose',
    },
    {
      id: 'promptTextAnimal',
      visibility: 'all',
      format: 'text',
      questionHeading: 'If you could be an animal, which one would you choose, and why?',
      answeredHeading: 'If I could be an animal, this is the one I’d choose',
    },
    {
      id: 'promptTextApp',
      visibility: 'all',
      format: 'text',
      questionHeading: 'Which app on your phone is the most important to you?',
      answeredHeading: 'The most important app on my phone',
    },
    {
      id: 'promptLinkApp',
      visibility: 'history',
      format: 'link',
      questionHeading: 'Share a link to the most important app on your phone, and tell us why.',
      answeredHeading: 'The most important app on my phone',
    },
    {
      id: 'promptTextWeirdestEaten',
      visibility: 'all',
      format: 'text',
      questionHeading: 'What’s the weirdest thing you’ve ever eaten?',
      answeredHeading: 'The weirdest thing I’ve ever eaten',
    },
    {
      id: 'promptImageWeirdestEaten',
      visibility: 'history',
      format: 'image',
      questionHeading: 'Share a photo of the weirdest thing you’ve ever eaten.',
      answeredHeading: 'The weirdest thing I’ve ever eaten',
    },
    {
      id: 'promptTextFaveRestaurant',
      visibility: 'all',
      format: 'text',
      questionHeading: 'What is your favorite restaurant, and why?',
      answeredHeading: 'My favorite restaurant',
    },
    {
      id: 'promptLinkFaveRestaurant',
      visibility: 'history',
      format: 'link',
      questionHeading: 'Share a link to your favorite restaurant, and tell us why.',
      answeredHeading: 'My favorite restaurant',
    },
    {
      id: 'promptText',
      visibility: 'all',
      format: 'text',
      questionHeading: 'If you could only eat one meal for the rest of your life, what would it be, and why?',
      answeredHeading: 'If I could only eat one meal for the rest of my life',
    },
    {
      id: 'promptText',
      visibility: 'all',
      format: 'text',
      questionHeading: 'If you had to teach a class, what would it be about?',
      answeredHeading: 'If I had to teach a class',
    },
    {
      id: 'promptTextRememberedFor',
      visibility: 'all',
      format: 'text',
      questionHeading: 'What would you like to be known or remembered for?',
      answeredHeading: 'What I’d like to be known or remembered for',
    },
    {
      id: 'promptTextTimeOfDay',
      visibility: 'all',
      format: 'text',
      questionHeading: 'What is your favorite time of day, and why?',
      answeredHeading: 'My favorite time of day',
    },
    {
      id: 'promptTextSeason',
      visibility: 'all',
      format: 'text',
      questionHeading: 'What is your favorite season, and why?',
      answeredHeading: 'My favorite season',
    },
    {
      id: 'promptTextWeather',
      visibility: 'all',
      format: 'text',
      questionHeading: 'What is your favorite kind of weather, and why?',
      answeredHeading: 'My favorite kind of weather',
    },
    {
      id: 'promptTextActor',
      visibility: 'all',
      format: 'text',
      questionHeading: 'Which actor would you pick to star in a movie about your life?',
      answeredHeading: 'The actor I’d choose to star in a movie about my life',
    },
    {
      id: 'promptLinkMovieTrailer',
      visibility: 'all',
      format: 'link',
      questionHeading: 'What’s a recent movie trailer you liked, and why did you like it?',
      answeredHeading: 'A movie trailer I liked',
    },
    {
      id: 'promptLinkVideoLaugh',
      visibility: 'all',
      format: 'link',
      questionHeading: 'Share a video that made you laugh.',
      answeredHeading: 'A video that made me laugh',
    },
    {
      id: 'promptTextFaveTVShow',
      visibility: 'all',
      format: 'text',
      questionHeading: 'What is your favorite TV show, and why?',
      answeredHeading: 'My favorite TV show',
    },
    {
      id: 'promptTextMovieSeeAgain',
      visibility: 'all',
      format: 'text',
      questionHeading: 'If you could see one movie again for the first time, which one would it be, and why?',
      answeredHeading: 'The movie I wish I could see again for the first time',
    },
    {
      id: 'promptTextOneAlbum',
      visibility: 'all',
      format: 'text',
      questionHeading: 'If you could only play one album for the rest of your life, which one would it be, and why?',
      answeredHeading: 'If I could only play one album for the rest of my life',
    },
    {
      id: 'promptLinkOneAlbum',
      visibility: 'history',
      format: 'link',
      questionHeading: 'Share a link to the album you’d choose if you could only play one for the rest of your life.',
      answeredHeading: 'If I could only play one album for the rest of my life',
    },
    {
      id: 'promptTextSportWatch',
      visibility: 'all',
      format: 'text',
      questionHeading: 'What is your favorite sport to watch, and why?',
      answeredHeading: 'My favorite sport to watch',
    },
    {
      id: 'promptTextHobby',
      visibility: 'all',
      format: 'text',
      questionHeading: 'What is your favorite hobby, and why?',
      answeredHeading: 'My favorite hobby',
    },
    {
      id: 'promptTextBestAt',
      visibility: 'all',
      format: 'text',
      questionHeading: 'What is the one thing you think you’re best at?',
      answeredHeading: 'The one thing I’m best at',
    },
    {
      id: 'promptTextCharacter',
      visibility: 'all',
      format: 'text',
      questionHeading: 'If you could be any fictional character, which one would you choose, and why?',
      answeredHeading: 'The fictional character I’d like to be',
    },
    {
      id: 'promptImageCharacter',
      visibility: 'history',
      format: 'image',
      questionHeading: 'Share a photo of the one fictional character you wish you could be.',
      answeredHeading: 'The fictional character I’d like to be',
    },
    {
      id: 'promptImageOffice',
      visibility: 'all',
      format: 'image',
      questionHeading: 'What does your workplace look like right now?',
      answeredHeading: 'My workplace',
    },
    {
      id: 'promptImageOutfit',
      visibility: 'all',
      format: 'image',
      questionHeading: 'What’s a favorite outfit you recently wore?',
      answeredHeading: 'A recent favorite outfit',
    },
    {
      id: 'promptImageBreakfast',
      visibility: 'all',
      format: 'image',
      questionHeading: 'Share a photo of your breakfast.',
      answeredHeading: 'My breakfast',
    },
    {
      id: 'promptImageWeatherNow',
      visibility: 'all',
      format: 'image',
      questionHeading: 'Share a picture of the current weather where you are.',
      answeredHeading: 'The weather where I am',
    },
    {
      id: 'promptImageFaveShirt',
      visibility: 'all',
      format: 'image',
      questionHeading: 'What does your favorite shirt look like?',
      answeredHeading: 'My favorite shirt',
    },
    {
      id: 'promptImageMeRightNow',
      visibility: 'all',
      format: 'image',
      questionHeading: 'Take a photo of yourself right now and share it!',
      answeredHeading: 'Me, right now',
    },
    {
      id: 'promptImageSky',
      visibility: 'all',
      format: 'image',
      questionHeading: 'What does the sky look like right now where you are?',
      answeredHeading: 'The sky above me right now',
    },
    {
      id: 'promptImageCloseup',
      visibility: 'all',
      format: 'image',
      questionHeading: 'Share a close-up photo of something.',
      answeredHeading: 'A close-up photo',
    },
    {
      id: 'promptImageThankful',
      visibility: 'all',
      format: 'image',
      questionHeading: 'Share a picture of something you’re thankful for, and tell us why.',
      answeredHeading: 'Something I’m thankful for',
    },
    {
      id: 'promptLinkNews',
      visibility: 'all',
      format: 'link',
      questionHeading: 'What is your main online source for news, and why?',
      answeredHeading: 'My main source for news online',
    },
    {
      id: 'promptLinkChangedOpinion',
      visibility: 'all',
      format: 'link',
      questionHeading: 'What is a web page that changed your opinion about something, and why?',
      answeredHeading: 'A page that changed my opinion',
    },
    {
      id: 'promptLinkMeaningfulCause',
      visibility: 'all',
      format: 'link',
      questionHeading: 'What’s a cause or issue you care about, and why is it important to you?',
      answeredHeading: 'A group or cause or issue I care about',
    },
    {
      id: 'promptLinkFaveTikTok',
      visibility: 'all',
      format: 'link',
      questionHeading: 'What’s your favorite TikTok account right now?',
      answeredHeading: 'My favorite TikTok account right now',
    },
    {
      id: 'promptLinkFaveIG',
      visibility: 'all',
      format: 'link',
      questionHeading: 'What’s your favorite Instagram account right now?',
      answeredHeading: 'My favorite Instagram account right now',
    },
    {
      id: 'promptLinkYoutuber',
      visibility: 'all',
      format: 'link',
      questionHeading: 'Who’s your favorite YouTuber or streamer right now?',
      answeredHeading: 'My favorite YouTuber or streamer right now',
    },
    {
      id: 'promptLinkUnusualStore',
      visibility: 'all',
      format: 'link',
      questionHeading: 'What’s the most unusual place you’ve ever bought from online, and what did you get?',
      answeredHeading: 'The most unusual place I’ve ever bought from online',
    },
    {
      id: 'promptLinkFaveRecipe',
      visibility: 'all',
      format: 'link',
      questionHeading: 'Share a link to a favorite recipe, and say why you think people should try it.',
      answeredHeading: 'A favorite recipe',
    },
    {
      id: 'promptLinkHometown',
      visibility: 'all',
      format: 'link',
      questionHeading: 'Tell us where you grew up, and what it was like there.',
      answeredHeading: 'Where I grew up',
    },
    {
      id: 'promptLinkWeird',
      visibility: 'all',
      format: 'link',
      questionHeading: 'What’s the weirdest page you’ve ever seen on the Web?',
      answeredHeading: 'The weirdest web page I’ve ever seen',
    },
    {
      id: 'promptLinkHowtoVideo',
      visibility: 'all',
      format: 'link',
      questionHeading: 'Share a video that helped you learn how to do something hard.',
      answeredHeading: 'A video that helped me learn something hard',
    },
    {
      id: 'promptLinkFaveWikipedia',
      visibility: 'all',
      format: 'link',
      questionHeading: 'What’s your favorite Wikipedia entry?',
      answeredHeading: 'My favorite Wikipedia entry',
    },
    {
      id: 'promptLinkFunnyWikipedia',
      visibility: 'all',
      format: 'link',
      questionHeading: 'Share a Wikipedia entry that made you laugh.',
      answeredHeading: 'A Wikipedia entry that made me laugh',
    },
    {
      id: 'promptTextJoke',
      visibility: 'all',
      format: 'text',
      questionHeading: 'Tell a joke!',
      answeredHeading: 'A joke',
    },
    {
      id: 'promptTextLoss',
      visibility: 'all',
      format: 'text',
      questionHeading: 'What’s a personal setback or loss that you’ve endured, and what did it teach you?',
      answeredHeading: 'A setback or loss I’ve endured',
    },
    {
      id: 'promptTextDogsCats',
      visibility: 'all',
      format: 'text',
      questionHeading: 'Dogs or cats? Why?',
      answeredHeading: 'Dogs or cats?',
    },
    {
      id: 'promptTextChildhoodMemory',
      visibility: 'all',
      format: 'text',
      questionHeading: 'What’s your earliest childhood memory?',
      answeredHeading: 'My earliest childhood memory',
    },
    {
      id: 'promptImageFirstPhoto',
      visibility: 'all',
      format: 'image',
      questionHeading: 'Share the oldest photo of yourself that you can find.',
      answeredHeading: 'The oldest photo of me',
    },
    {
      id: 'promptImagePet',
      visibility: 'all',
      format: 'image',
      questionHeading: 'Share a photo of your pet or pets.',
      answeredHeading: 'My pet(s)',
    },
    {
      id: 'promptTextZombie',
      visibility: 'all',
      format: 'text',
      questionHeading: 'Which fictional character would you want by your side during a zombie apocalypse?',
      answeredHeading: 'The fictional character I’d want by my side during a zombie apocalypse',
    },
  ],

  mkDefaultProfile: () =>
    // DRY_r1622 initial state of an empty profile
    // default values in new profiles or when a user deletes/resets part of their
    // profile.
    ({
      version: constants.CURRENT_VERSION,
      name: '',
      avatarPublicID: '',
      promptResponses: [],
    }),

  normalizeUrlConfig: {
    // we're not using normalizeUrl to strip down urls to their essentials, we
    // are using it as a user input parser that is more lenient than URL() lib.
    // for example you can give it 'twitter.com/foo' and it will return
    // 'https://twitter.com/foo' where URL() would throw an error.
    defaultProtocol: 'https:',
    removeTrailingSlash: false,
    removeSingleSlash: false,
    stripTextFragment: false,
    stripAuthentication: false,
    stripHash: false,
    stripProtocol: false,
    stripWWW: false,
    removeDirectoryIndex: false,
    sortQueryParameters: false,
  },
};

export { constants };
