import { observer } from 'mobx-react-lite';
import { useStore } from 'store';
import { BasicsEditor } from 'component/BasicsEditor';
import { BasicsBar } from 'component/BasicsBar';
import { UnmountClosed } from 'react-collapse';

const Basics = observer(() => {
  const store = useStore();
  let barOpen = store.userIsAtOrBeyondStep('needsFirstPrompt')
  const editorOpen = !barOpen
  // see store.setClickedConfirmBasics for suppressBasicsBarOnTimer explanation
  barOpen = barOpen && !store.suppressBasicsBarOnTimer
  return (
    <>
      <UnmountClosed isOpened={barOpen}>
        <BasicsBar />
      </UnmountClosed>
      <UnmountClosed isOpened={editorOpen}>
        <BasicsEditor isDrawer={false} inBasicsInitialEditor={true} />
      </UnmountClosed>
    </>
  );
});

export { Basics };
