const channels = {
  warn: {
    default: true,
    test: true,
    inProdBrowser: true,
  },
  error: {
    default: true,
    test: true,
    inProdBrowser: true,
  },
  auth: {
    default: true,
    test: false,
    inProdBrowser: false,
  },
  readiness: {
    default: true,
    test: false,
    inProdBrowser: false,
  },
  api: {
    default: true,
    test: false,
    inProdBrowser: false,
  },
  unfurl: {
    default: true,
    test: false,
    inProdBrowser: false,
  },
  location: {
    default: true,
    test: false,
    inProdBrowser: false,
  },
  sql: {
    default: true,
    test: false,
    inProdBrowser: false,
  },
  tracking: {
    default: true,
    test: false,
    inProdBrowser: false,
  },
};

const log = {};
let config = 'default';

if (process.env.NODE_ENV === 'production' && typeof window !== 'undefined') {
  // in production browser context we use a more limited set of active logging
  // channels
  config = 'inProdBrowser'
}

function setConfig(x) {
  config = x;
  assign();
}

function assign() {
  for (let channel in channels) {
    if (channels[channel][config]) {
      log[channel] = (...args) => console.log(channel, ':', ...args);
    } else {
      log[channel] = () => false;
    }
  }
}

function setTestMode() {
  setConfig('test');
}

assign();

export default log;

export { setTestMode };
