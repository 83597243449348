/** @jsxImportSource @emotion/react */
import { Avatar } from 'component/Avatar';
import { Name } from 'component/Name';
import { OnboardingTip } from 'component/OnboardingTip';
import { Slug } from 'component/Slug';
import { Button, H, Icon, P } from 'component/uiLib';
import { PageContentLimit } from 'component/uiLib/PageContentLimit';
import { observer } from 'mobx-react-lite';
import { useStore } from 'store';
import { ReactComponent as Pencil } from 'shared-copied/assets/images/icons/pencil.svg';

const BasicsEditor = observer(
  ({ isDrawer, onClose, inBasicsInitialEditor }) => {
    const store = useStore();
    return (
      <PageContentLimit
        leftCol={
          !isDrawer && (
            <OnboardingTip inBasicsInitialEditor={inBasicsInitialEditor} />
          )
        }
      >
        <H h4 bold style={{ margin: 0, textAlign: 'center' }}>
          <Pencil
            style={{
              height: '22px',
              width: '22px',
              marginRight: '18px',
              position: 'relative',
              top: '2px',
            }}
          />
          Your account basics
        </H>
        <P md>
          All three of these settings are required in order for your Grac3land
          page to work.
        </P>
        <Name />
        <Slug />
        <Avatar />
        {store.getCurrentOnboardingStep() === 'needsConfirmBasics' && (
          <Button onClick={store.setClickedConfirmBasics} primary>
            Get started
          </Button>
        )}
      </PageContentLimit>
    );
  }
);

export { BasicsEditor };
