/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { H, P, Button, Card } from 'component/uiLib';
import { ReactComponent as LinkIcon } from 'shared-copied/assets/images/icons/link.svg';

const StyledAnswerField = ({
  field,
  value,
  onChange,
  title,
  placeholder,
  maxChars,
  validationError,
}) => {
  let classes = ['thefield', 'body-text-s'];
  const empty = ((value || '').trim() === '')
  if (empty) classes.push('empty');
  if (validationError) classes.push('invalid');
  let veText = {
    veTooLong: 'Too long',
    veInvalidUrl: 'Please enter a valid URL: https://...',
    veRequired: 'Required',
  }[validationError];
  const tooLong = (value || '').length > maxChars
  if (field === 'text' || field === 'urlCaption' || field === 'imageCaption') {
    return (
      <div
        className="our-forms"
        css={css`
          display: grid;
          grid-auto-flow: row;
          grid-gap: 5px;
          grid-template-areas:
            'title'
            'field'
            'validation';
          & > * {
            margin: 0;
          }
          & .thetitle {
            grid-area: title;
          }
          & .thefield {
            grid-area: field;
          }
          & .themagic {
            /* DRY_71026 textarea metrics */
            appearance: none;
            visibility: hidden;
            pointer-events: none;
            box-sizing: border-box;
            width: 100%;
            grid-area: field;
            border: 1px solid transparent;
            white-space: pre-wrap;
            word-wrap: break-word;
            color: red;
            font-size: 14px;
            line-height: 22px;
            font-weight: 400;
            padding: 3px;
            font-family: 'Space Grotesk', sans-serif;
            max-height: 300px;
          }
          & .thevalidation {
            grid-area: validation;
          }
        `}
      >
        <P sm className="thetitle">{title}</P>
        <textarea
          className={classes.join(' ')}
          rows={4}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
        />
        {/*
        what's themagic? it's the div that causes the textfield to expand in
        size when text otherwise overflows it.  how it works:
        - setup a <pre> element that has the same layout metrics as the
        textarea. if this field were visible, the text should overlap the
        textarea's text basically perfectly.
        - add a <br> into it because you need that in case there's a trailing
        empty line in the textarea.
        - make this element use the same grid cell as the textarea, thus
        overlapping it and expanding the grid cell and the textarea's automatic
        height sizing follows suite.
        - but set it to appearance: none, visibility: hidden, and for good
        measure pointer-events: none to take it fully out of the ux.
        - set the <pre> element max-height to the max you want the textarea to grow to
        this is inspired by a few articles:
        http://alistapart.com/article/expanding-text-areas-made-elegant/
        https://css-tricks.com/auto-growing-inputs-textareas/ and https://codepen.io/shshaw/pen/bGNJJBE
        */}
        <pre className="themagic like-a-textarea">{value}<br/></pre>
        <div
          className="thevalidation"
          css={css`
            display: grid;
            grid-template-columns: 1fr auto;
            grid-gap: 5px;
            & > * {
              margin: 0;
            }
          `}
        >
          <P sm className="body-text-error">{veText}</P>
          <P sm className={(tooLong ? "body-text-error" : "body-text-light")}>
            {value.length}/{maxChars}
          </P>
        </div>
      </div>
    );
  }
  if (field === 'url' || field === 'url') {
    // DRY_39032 theme colors
    // because i need to apply alpha here, i can't use var(--color-charcoal)
    // and what not unless i convert the vars to decimal rgb values and use
    // rgba(var(--color-charcol), 0.8) etc. but decimal conversion is a PITA
    // and i'm in a hurry.
    let linkIconSaturated = !(empty || validationError);
    return (
      <div
        className="our-forms"
        css={css`
          display: grid;
          grid-auto-flow: row;
          grid-gap: 5px;
          & > * {
            margin: 0;
          }
        `}
      >
        <P sm>{title}</P>
        <div
          css={css`
            display: flex;
            flex-direction: row;
            align-items: center;
            & > .adornment {
              width: 1px;
              overflow: visible;
            }
            & > .adornment svg {
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              padding: 4px 6px;
              border-right: 1px solid #0d0d0d;
              filter: grayscale(100%) opacity(${linkIconSaturated ? '100%': '20%'});
            }
            & > input {
              padding-left: 40px;
              margin-left: -1px;
              flex-grow: 1;
            }
          `}
        >
          <div
            className="adornment"
            css={css`
              position: relative;
            `}
          >
            <LinkIcon style={{width:'18px',height:'18px'}}/>
          </div>
          <input
            className={classes.join(' ')}
            type="text"
            value={value}
            onChange={onChange}
            placeholder={placeholder}
          />
        </div>
        <div
          css={css`
            display: grid;
            grid-template-columns: 1fr auto;
            grid-gap: 5px;
            & > * {
              margin: 0;
            }
          `}
        >
          <P sm className="body-text-error">{veText}</P>
          <P sm className={(tooLong ? "body-text-error" : "body-text-light")}>
            {value.length}/{maxChars}
          </P>
        </div>
      </div>
    );
  }
  return false;
};

export { StyledAnswerField };
