import { useStore } from 'store';
import { trackingEvents } from 'shared-copied/constants.mjs';
import { H, P } from 'component/uiLib';
import { PageContentLimit } from 'component/uiLib/PageContentLimit';

function NotFound() {
  const store = useStore();
  store.useTrackedPageEvent(trackingEvents.pvNotFound);
  return (
    <PageContentLimit textualPage>
      <div data-testid="pageNotFound">
        <H h3 mv={36}>Page Not Found</H>
        <P>Oh snap! The page you’re looking for doesn’t exist.</P>
        {store.locationCouldPossiblyBeASlug404() && (
          <P>
            If this used to be the homepage of a Grac3land user, perhaps they’ve
            changed their URL.
          </P>
        )}
      </div>
    </PageContentLimit>
  );
}

export default NotFound;
