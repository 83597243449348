/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Player } from '@lottiefiles/react-lottie-player';
import { breakpoints, Button, H, Icon, P } from 'component/uiLib';
import { ConfirmationModal } from 'component/uiLib/ConfirmationModal';
import { CoverSpinner } from 'component/uiLib/CoverSpinner';
import { makeAutoObservable } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import successAnimationJson from 'shared-copied/assets/images/animation/prompt_completed_lottie.json';

class AnimStore {
  // the cycle of the prompter:
  // - composing
  // - saving
  // - success_enter
  // - success_collapse
  // - success_render
  // - next_enter
  // - next_collapse
  // - next_expand
  //
  // the full cycle is exercised when the user saves a prompt. if the user hits
  // 'give me another' button w/o a save, we go from 'composing' to
  // 'next_enter' directly.
  state = 'composing';

  // timing
  heightTransitionDuration = 0.3;
  opacityTransitionDuration = 0.4;
  fakePromptDelay = 0.15;
  easing = 'ease-in';

  // height values. all are integer px, and prompterHeight can alternately be 'auto'
  successCollapsedHeight = 400;
  nextCollapsedHeight = 250;
  prompterHeight = 'auto'; // either an int or 'auto'. starts 'auto'
  headerHeight = 0;
  frameContentHeightDelta = 0;

  // turning things on and off
  showCoverSpinner = false;
  showSuccessOverlay = false;
  showSuccessContent = false;
  successContentOpacity = 0;
  showSaveButtonSpinner = false;

  // - composing: the user sees the prompt form, fully interactable.
  //
  // [wait for save button]
  //
  // - saving: the user clicked save button and we are doing a network request.
  // put a spinner on the save button.
  //
  // [wait for network request]
  //
  // - success_enter: the network request succeeded. put the success overlay
  // in place with the content set to opacity 0 but the background instantly
  // opaque white. set height of prompter to current height to prep for
  // animation.
  //
  // [wait 1 tick]
  //
  // - success_collapse: set height to the height of collapsed success state to
  // start the height transition. 
  //
  // [wait for transition]
  //
  // - success_render: render the success animation and button in the success
  // content region, set opacity to 1. trigger scrollIntoView() on the prompter
  // div.
  //
  // [wait for user to hit next button, the css transition is naturally
  // included in this waiting since the user won't hit the button until its
  // visible.]
  //
  // - next_enter: show the full cover spinner (instantly) and set height of
  // prompter from auto to current px so we can start animating it.
  //
  // [wait 1 tick]
  //
  // - next_collapse: set height of prompter to the collapsed height in px. fire
  // off the onAdvance event to get the next prompt data setup, the form will
  // render behind the cover during the height collapse animation.
  //
  // [wait for the collapse duration plus our additional fake processing delay
  // time]
  //
  // - next_expand: measure the hidden content form's new px height and set the
  // prompter's height to it to start animating the size again.
  //
  // [wait for transition]
  //
  // return to composing


  /* animating the height of a css element with dynamic content, where height
   * is by default 'auto', is not directly supported by css. so what we do is:
   * 1. show a spinner over the content area, measure the current content area
   * size and set the prompter to a specific size that is identical.
   * 2. allow the content to update. it will be hid behind the spinner, and if
   * it grows larger, we have overflow:hidden so it wont be seen. if it shrinks,
   * the specific height in px of the promtper doesn't change so nothing appears
   * to change.
   * 2. remeasure the new hidden content and set the height to this specific
   * next value. the css transition is in effect here, and it smoothly grows
   * or shrinks.
   * 3. after the css transition, restore height to auto.
   * */

  constructor(onSave, onAdvance) {
    this.onSave = onSave;
    this.onAdvance = onAdvance;
    makeAutoObservable(
      this,
      { onSave: false, onAdvance: false },
      { autoBind: true }
    );
  }

  userActionSave() {
    this.setState('saving');
  }

  userActionGetNextPrompt() {
    this.setState('next_enter');
  }

  setState(newState) {
    this.state = newState;
    console.log('new state', newState);
    const curFrameHeight = window.document.getElementById(
      'prompter-frame'
    ).clientHeight;
    const curContentHeight = window.document.getElementById(
      'prompter-content'
    ).clientHeight;
    // headerHeight is needed in rendering to set the overlays just below it.
    // without this, we can't easily organize the overlays in the dom tree in a
    // way that resizes with the prompter frame and shows over the content.
    this.headerHeight = window.document.getElementById(
      'prompter-header'
    ).clientHeight;
    if (this.prompterHeight === 'auto') {
      // compute the value we need to add to content div to get the height for
      // the frame. includes the header height, and borders or padding on the
      // frame, margin around content, etc.
      this.frameContentHeightDelta = curFrameHeight - curContentHeight;
    }
    if (newState === 'composing') {
      this.prompterHeight = 'auto';
      this.showSaveButtonSpinner = false;
      this.showSuccessOverlay = false;
      this.showSuccessContent = false;
      this.showCoverSpinner = false;
    }
    if (newState === 'saving') {
      this.prompterHeight = 'auto';
      this.showSaveButtonSpinner = true;
      this.showSuccessOverlay = false;
      this.showSuccessContent = false;
      this.showCoverSpinner = false;
      this.onSave()
        .then(() => {
          this.setState('success_enter');
        })
        .catch(e => {
          this.setState('composing');
        });
    }
    if (newState === 'success_enter') {
      this.prompterHeight = curFrameHeight
      this.easing = 'ease-out';
      this.showSuccessOverlay = true;
      this.showSuccessContent = false;
      this.successContentOpacity = 0;
      this.showCoverSpinner = false;
      this.showSaveButtonSpinner = true;
      setTimeout(() => this.setState('success_collapse'), 10);
    }
    if (newState === 'success_collapse') {
      this.prompterHeight = this.successCollapsedHeight;
      this.showSuccessOverlay = true;
      this.showSuccessContent = false;
      this.successContentOpacity = 1;
      this.showCoverSpinner = false;
      this.showSaveButtonSpinner = false;
      setTimeout(() => this.setState('success_render'), this.heightTransitionDuration * 1000);
    }
    if (newState === 'success_render') {
      this.prompterHeight = this.successCollapsedHeight;
      this.showSuccessOverlay = true;
      this.showSuccessContent = true;
      this.successContentOpacity = 1;
      this.showCoverSpinner = false;
      this.showSaveButtonSpinner = false;
      window.document.getElementById(
        'prompter-frame'
      ).scrollIntoView({behavior: "smooth", block: "center"});
    }
    if (newState === 'next_enter') {
      // using curFrameHeight not necessarily successCollapsedHeight because we
      // might be doing a 'gimme another' cycle and not a save cycle. 
      this.prompterHeight = curFrameHeight;
      this.easing = 'ease-out';
      this.showSaveButtonSpinner = false;
      this.showSuccessOverlay = false;
      this.showSuccessContent = true;
      this.showCoverSpinner = true;
      setTimeout(() => this.setState('next_collapse'), 10);
    }
    if (newState === 'next_collapse') {
      this.prompterHeight = this.nextCollapsedHeight;
      this.showCoverSpinner = true;
      this.showSaveButtonSpinner = false;
      this.showSuccessOverlay = false;
      this.showSuccessContent = true;
      this.onAdvance();
      setTimeout(
        () => this.setState('next_expand'),
        (this.fakePromptDelay + this.heightTransitionDuration) * 1000
      );
    }
    if (newState === 'next_expand') {
      this.prompterHeight = curContentHeight + this.frameContentHeightDelta
      this.easing = 'ease-in';
      this.showCoverSpinner = true;
      this.showSaveButtonSpinner = false;
      this.showSuccessOverlay = false;
      this.showSuccessContent = true;
      setTimeout(
        () => this.setState('composing'),
        this.heightTransitionDuration * 1000
      );
    }
  }
}

const Frame = observer(({ children, animStore }) => {
  let height = animStore.prompterHeight
  if (height !== 'auto') height = height + 'px'
  return (
    <div
      id="prompter-frame"
      css={css`
        position: relative;
        background-color: var(--color-opaque-white);
        border: 2px solid #0d0d0d;
        border-radius: 4px;
        padding: 0;
        margin: 0;
        text-align: center;
        height: ${height};
        transition: height ${animStore.heightTransitionDuration}s ${animStore.easing};
        overflow: hidden;
      `}
    >
      {children}
    </div>
  );
});

const NewPromptHead = () => (
  <H h4 bold
    id="prompter-header"
    css={css`
      background-color: var(--color-charcoal);
      padding: 10px 5px;
      color: var(--color-opaque-white);
      margin: 0;
    `}
  >
    <Icon.AutoAwesome sx={{ color: '#fc0', fontSize: '80%', mr: 1 }} />
    New Question
    <Icon.AutoAwesome sx={{ color: '#fc0', fontSize: '80%', ml: 1 }} />
  </H>
);

const SuccessOverlay = observer(({ animStore }) => {
  return (
    <div
      css={css`
        position: absolute;
        top: ${animStore.headerHeight}px;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: var(--color-opaque-white);
        z-index: 100;
        display: grid;
        grid-template-columns: 100%;
        align-items: center;
        justify-items: center;
        text-align: center;
      `}
    >
      {animStore.showSuccessContent && (
      <div
        css={css`
          opacity: ${animStore.successContentOpacity};
          transition: opacity ${animStore.opacityTransitionDuration}s;
          `}>
        <Player
          autoplay
          keepLastFrame
          src={successAnimationJson}
          style={{ height: '188px' }}
        />
        <div>
          <H h4 bold m='0 0 16px 0'>Answer Saved!</H>
          <Button primary onClick={animStore.userActionGetNextPrompt}>
            Give me another one!
          </Button>
        </div>
      </div>)}
    </div>
  );
});

const StyledPrompter = observer(
  ({
    promptHeading,
    formFields,
    onSave,
    onAdvance,
    isValid,
    allPromptsComplete,
    hasAnyUGC,
    disableBreakpoints,
  }) => {
    const [animStore] = useState(() => new AnimStore(onSave, onAdvance));

    const [confirmNext, setConfirmNext] = useState(false);

    const ourOnAdvance = opts => {
      opts = opts || {};
      if (hasAnyUGC && !opts.confirmed) {
        setConfirmNext(true);
        return;
      }
      animStore.userActionGetNextPrompt();
    };

    const onYes = () => {
      setConfirmNext(false);
      ourOnAdvance({ confirmed: true });
    };

    const onNo = () => {
      setConfirmNext(false);
    };

    if (allPromptsComplete) {
      return (
        <Frame animStore={animStore}>
          <NewPromptHead />
          <div id="prompter-content">
            <P>Holy smokes! You completed them all.</P>
          </div>
        </Frame>
      );
    }

    return (
      <Frame animStore={animStore}>
        {animStore.showCoverSpinner && (
          <CoverSpinner top={animStore.headerHeight} />
        )}
        {animStore.showSuccessOverlay && (
          <SuccessOverlay animStore={animStore} />
        )}

        <ConfirmationModal
          open={confirmNext}
          onYes={onYes}
          onNo={onNo}
          title="Discard your current answer?"
          body="The content you’ve entered so far will be lost."
          yesText="Yes"
          noText="Cancel"
        />
        <NewPromptHead />
        <div id="prompter-content">
          <div style={{ padding: '24px' }}>
            <H h4 bold m='24px 0 24px 0'>
              {promptHeading}
            </H>
            <div style={{ textAlign: 'left' }}>
              <div
                css={css`
                  display: grid;
                  grid-gap: 24px;
                  grid-auto-flow: row;
                `}
              >
                {formFields}
                <div
                  css={css`
                    border-top: 1px solid var(--color-border-light);
                    padding-top: 24px;
                    display: grid;
                    grid-gap: 10px;
                    grid-template-columns: 1fr 1fr;
                    ${breakpoints.mq.down.xs} {
                      ${!disableBreakpoints &&
                      `
                      grid-template-columns: 1fr;
                      grid-template-rows: auto auto;
                      `}
                    }
                  `}
                >
                  <Button
                    primary
                    onClick={animStore.userActionSave}
                    disabled={!isValid}
                    loading={animStore.showSaveButtonSpinner}
                  >
                    Done
                  </Button>
                  <Button secondary onClick={ourOnAdvance}>
                    Give me another one!
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Frame>
    );
  }
);

export { StyledPrompter };
