/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { ReactComponent as LinkIcon } from 'shared-copied/assets/images/icons/link.svg';
import { ReactComponent as ImageIcon } from 'shared-copied/assets/images/icons/profile_avatar.svg';
import { ReactComponent as TextIcon } from 'shared-copied/assets/images/icons/pencil.svg';
import { ReactComponent as SlugIcon } from 'shared-copied/assets/images/icons/profile_slug.svg';
import { ReactComponent as NameIcon } from 'shared-copied/assets/images/icons/profile_name.svg';
import { ReactComponent as CheckIcon } from 'shared-copied/assets/images/icons/complete_check.svg';

const EncircledIcon = ({ name, ...props }) => {
  let bgColor = 'var(--color-icon-container)';
  if (name === 'check') bgColor = '#b4ffb7';
  return (
    <div
      {...props}
      css={css`
        display: inline-block;
        background-color: ${bgColor};
        border-radius: 100%;
        padding: 11px;
        line-height: 1px;
        & svg {
          width: 22px;
          height: 22px;
        }
      `}
    >
      {name === 'name' && <NameIcon />}
      {name === 'slug' && <SlugIcon />}
      {name === 'avatar' && <ImageIcon />}

      {name === 'check' && <CheckIcon />}

      {name === 'text' && <TextIcon />}
      {name === 'link' && <LinkIcon />}
      {name === 'image' && <ImageIcon />}
    </div>
  );
};

export { EncircledIcon };
