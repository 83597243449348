import parse from 'html-react-parser';
import { TERMS_OF_SERVICE_CONTENT } from './copy';
import { trackingEvents } from 'shared-copied/constants.mjs';
import { useStore } from 'store';
import { PageContentLimit } from 'component/uiLib/PageContentLimit';

export const TermsOfService = () => {
  const store = useStore();
  store.useTrackedPageEvent(trackingEvents.pvTerms);
  return (
    <PageContentLimit textualPage>
      {parse(TERMS_OF_SERVICE_CONTENT)}
    </PageContentLimit>
  );
};
