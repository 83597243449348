import { action, computed, makeAutoObservable, reaction, toJS } from 'mobx';
import apiClient from 'shared-copied/apiClient.mjs';
import log from 'shared-copied/log.mjs';
import { UserProfile } from 'shared-copied/UserProfile/index.mjs';
import { constants as userProfileConstants } from 'shared-copied/UserProfile/constants.mjs';
import { isEqual, debounce } from 'lodash-es';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import clientAppPaths from 'clientAppPaths';
import { createBrowserHistory } from 'history';
import * as amplitude from '@amplitude/analytics-browser';
import { trackingEvents } from 'shared-copied/constants.mjs';
import { useEffect } from 'react';

// object lifecycle:
// the mobx store is an application singleton instantiated once and then made
// available via context through the the StoreWrapper component which is very
// near the top of the react component tree.
//
// making it a singleton, rather than creating it via useState() avoids quite
// some headache in respecting component lifecycle.  even though our top level
// context wrapper component is only used once and never changes, react strict
// mode in dev forces all components through the lifecycle and thus our store
// setup would have to deal with getting run through that wash cycle, and
// results in things like double api calls from boot() logic,
// registering/unregistering the history listener, etc.
//
// location history:
// the mobx store is the owner for react router's browser history object, via a
// history object from the 'history' npm package, which is a dependency of
// react-router-dom. even though it's not defined directly as a dependency in
// package.json, it is still in our node modules. this dependency oddity is why
// HistoryRouter is called "unstable" - see
// https://reactrouter.com/en/v6.3.0/api#unstable_historyrouter the router
// component itself owns the job of making sure the component tree is updated
// based on location, but the mobx store "owns" that location, can change it
// directly via pushstate and such, and can implement it's own reactive logic
// when it changes as needed.

class Store {
  // ready becomes true after we've loaded the user from the api (get /me using
  // the session cookie), or gotten an unauth response from that api.
  ready = false;
  booted = false; // true on boot()
  location = false; // the history object, setup on boot
  showAuthComponents = false; // see clearUserBits() for explanation
  user = false; // false when unauthed, a user payload from the api when authed
  userProfile = false; // false when unauthed, a UserProfile object otherwise
  // a copy of the user profile but for use in editable forms. this way we have
  // the last known server state and the changes in the client separately.
  editingUserProfile = false;
  savingProfile = false; // for showing a spinner / tmp disabling forms
  slugValidationError = false;
  editingSlug = '';
  suggestedSlug = '';
  urlMetaProcessor = 'unfurl';
  firebaseRef = false;
  signupCode = false;
  allPrompts3xInRandomOrder = false;
  promptCounter = 0;
  allPromptsComplete = false;
  trackedPageEvent = false;
  clickedConfirmBasics = false;
  suppressBasicsBarOnTimer = false;
  generalError = false;

  firebaseModalOpen = false;
  loginButtonsSpinning = false;
  loginErrorMode = false;

  constructor() {
    makeAutoObservable(
      this,
      {
        firebaseRef: false,
        history: false,
        allPrompts3xInRandomOrder: false,
        trackedPageEvent: false,
      },
      { autoBind: true }
    );
  }

  boot() {
    log.readiness('store booting');
    if (this.booted) {
      log.warn('store boot() called more than once');
      return;
    }
    amplitude.init(process.env.REACT_APP_AMPLITUDE_API_KEY, '', {
      serverUrl: clientAppPaths.amplitudeProxy,
    });
    this.initLocation();
    this.beginLocationListener();
    this.initAuth();
    this.booted = true;
  }

  // {{{ location

  initLocation() {
    this.history = createBrowserHistory({ window });
    this.location = this.history.location;
    const url = new URL(window.location);
    // DRY_47693 signup code logic
    this.signupCode = url.searchParams.get('signupCode') || false;
  }

  beginLocationListener() {
    // start listening for location changes. there is no deregistration here,
    // this is a singleton that will listen for the lifetime of the page
    // context.
    this.history.listen(({ location, action }) => {
      // we can thus have mobx reactions to location changes
      log.location({ location, action });
      this.location = location;
    });
  }

  setLocation(x) {
    this.location = x;
  }

  locationCouldPossiblyBeASlug404() {
    const url = new URL(window.location);
    return !!url.pathname.match(/^\/[a-z0-9]+$/i);
  }

  // }}}

  // {{{ urls

  userHomepageUrlForSlug(slug) {
    return clientAppPaths.userHomepageUrlForSlug(slug);
  }

  get userHomepageUrl() {
    // this getter also returns the url and also proxies as truthy for when the
    // profile is live, for example:
    // if (store.userHomepageUrl) { assumes a live page } else { profile not
    // complete enough }
    if (!this.user) return undefined;
    if (!this.userProfile.isMinimallyComplete({ urlSlug: this.user.urlSlug }))
      return undefined;
    return this.userHomepageUrlForSlug(this.user.urlSlug);
  }

  get profileIsMinimallyComplete() {
    return !!this.userHomepageUrl;
  }

  // }}}

  // {{{ healthCheck

  getHealthCheck() {
    return apiClient.getHealthCheck();
  }

  // }}}

  // {{{ auth

  setReady(x) {
    log.readiness('store ready');
    this.ready = x;
  }

  initAuth() {
    let firebaseConfig;
    // DRY_63816 firebase client config (not secret, but does depend on
    // environment)
    // TODO: better environment config handling. i don't yet have a way to set
    // a different env var in test vs prod react client since the js bundle is
    // built by the appserver_prod image, which is shared in both environments
    // so the test/prod bundles are currently identical. however, the
    // REACT_APP_OPS_ENV var does give me a way to do this right here, for now.
    if (process.env.REACT_APP_OPS_ENV === 'dev') {
      // note that webpack bundle optimization will remove these config chunks
      // from production bundles because the conditions will be
      // deterministically false to the transpiler.
      firebaseConfig = {
        apiKey: 'AIzaSyAqyllYZkkqUSB0dcQYdl6epZiCatVGFfc',
        authDomain: 'grac3land-dev.firebaseapp.com',
        projectId: 'grac3land-dev',
        storageBucket: 'grac3land-dev.appspot.com',
        messagingSenderId: '613385491326',
        appId: '1:613385491326:web:851e0f20d74d07c53dd92f',
      };
    }
    if (process.env.REACT_APP_OPS_ENV === 'nonprod') {
      firebaseConfig = {
        apiKey: 'AIzaSyDKFg457lbVbAB_dcLXU-2foZkl96ayu6U',
        authDomain: 'moz-fx-future-products-nonprod.firebaseapp.com',
        projectId: 'moz-fx-future-products-nonprod',
        storageBucket: 'moz-fx-future-products-nonprod.appspot.com',
        messagingSenderId: '984891837435',
        appId: '1:984891837435:web:1c40610ed86016115d995e',
      };
    }
    if (process.env.REACT_APP_OPS_ENV === 'prod') {
      firebaseConfig = {
        apiKey: 'AIzaSyCBrJmCmx2HA2Q70m5P6oS4XGPGb9z9xBo',
        authDomain: 'moz-fx-future-products-prod.firebaseapp.com',
        projectId: 'moz-fx-future-products-prod',
        storageBucket: 'moz-fx-future-products-prod.appspot.com',
        messagingSenderId: '29393258446',
        appId: '1:29393258446:web:1c1f3bc100e5f679140d9f',
      };
    }
    const self = this;

    firebase.initializeApp(firebaseConfig);
    self.firebaseRef = firebase;

    // we disable client-side auth because we're using cookie session auth
    // instead. when session auth is active, the react app knows it's authed by
    // the result of the get /me api call.
    firebase.auth().setPersistence(firebase.auth.Auth.Persistence.NONE);

    apiClient
      .getMe({ signupCode: self.signupCode })
      .then(user => {
        log.readiness('initial getMe returned a user');
        self.setUser(user);
        self.setReady(true);
      })
      .catch(e => {
        if (e.apiInfo?.responseWrapper?.status === 401 || e.apiInfo?.responseWrapper?.status === 403) {
          log.readiness('initial getMe returned unauth');
          self.setReady(true);
        } else {
          // unknown. network error?
          this.setGeneralError('_get_me_first_fail_')
          self.setReady(true);
        }
      });

    firebase.auth().onAuthStateChanged(
      function (firebaseUser) {
        if (firebaseUser) {
          log.auth('firebase user', firebaseUser);
          log.readiness(
            'switch back to ready=false to handle firebase auth result'
          );
          self.setReady(false); // start the fullpage spinner again
          // and we can close the firebase ui container modal
          self.cancelGlobalLoginOverlay()
          firebaseUser
            .getIdToken()
            .then(idToken => apiClient.sessionLogin({ idToken }))
            // the GET me call here needs the signup code because it will
            // actually auto-vivify the user and register the signup event in
            // amplitude, which we want to associated with the code.
            .then(() => apiClient.getMe({ signupCode: self.signupCode }))
            .then(user => {
              log.readiness('acquired getMe user after firebase auth');
              self.setUser(user);
              self.trackEvent(trackingEvents.caLogin);
              self.setReady(true);
            })
            .catch(e => {
              log.error('error acquiring user onAuthStateChanged', e);
              // this is what happens if the user is banned, or if there's a
              // network error on any of the critical api sequences.  better
              // error handling is important but for now, we will just force a
              // page reload.
              // TODO: better handling here.
              window.location.reload();
            });
        } else {
          self.cancelGlobalLoginOverlay()
        }
      },
      function (error) {
        log.error(error);
        log.readiness('firebase auth error, set ready=true (still unauth)');
        self.cancelGlobalLoginOverlay()
        self.setReady(true);
      }
    );
  }

  launchGlobalLoginOverlay(overrideCodeCheck) {
    this.trackEvent(trackingEvents.bcLoginSignup);
    this.loginErrorMode = false;
    // DRY_47693 signup code logic
    if (overrideCodeCheck) {
      // when overrideCodeCheck is true, we found the signupCode was invalid
      // but presented the user with a "login with existing account" button.
      // if they do sign up this way, which is allowed via firebase, they end
      // up in the unsolicited state, same as if they used the signupCode=false
      // flow.
      this.firebaseModalOpen = true;
      return;
    }
    if (this.signupCode === false) {
      // with no code present, we don't show any special case ui, we just let
      // them sign in and if they sign up for a new account, they'll land on
      // an unsolicited user page.
      this.firebaseModalOpen = true;
      return;
    }
    this.loginButtonsSpinning = true;
    this.validateSignupCode().then(action(res => {
      this.loginButtonsSpinning = false;
      if (res.codeStatus === 'active') {
        this.firebaseModalOpen = true;
      } else if (res.codeStatus === 'inactive') {
        this.loginErrorMode = '_inactive_code_';
      } else {
        this.loginErrorMode = '_code_error_';
      }
    }));
  };

  cancelGlobalLoginOverlay() {
    this.firebaseModalOpen = false;
    this.loginErrorMode = false;
    this.loginButtonsSpinning = false;
  }

  setUser(x) {
    if (x) {
      log.auth('store acquired user', x);
      window.fullResetForTestUser = async confirm => {
        // test runners call this to reset test user profiles
        if (confirm !== 'confirm') throw new Error('arg must be "confirm"');
        let user = await apiClient.postUserProfile({
          fullResetForTestUser: confirm,
        });
        this.ingestUpdatedUser(user);
        user = await apiClient.postUrlSlug({ fullResetForTestUser: confirm });
        this.ingestUpdatedUser(user);
      };
      this.ingestUpdatedUser(x);
      amplitude.setUserId(x.id);
      this.showAuthComponents = true;
      // DRY_47693 signup code logic
      // if we have a signup code on the url, clear it
      if (this.signupCode) {
        log.location('removing signupCode from url');
        const url = new URL(window.location);
        url.searchParams.delete('signupCode');
        window.history.replaceState({}, '', url.toString());
        this.signupCode = false;
      }
      if (x.signupCodeName) {
        // if the user has a signup code, make sure it's set in the amplitude user properties
        log.tracking('identify() signupCode');
        const identifyOps = new amplitude.Identify();
        identifyOps.setOnce('signupCodeName', x.signupCodeName);
        identifyOps.setOnce('hasSignupCode', '1');
        amplitude.identify(identifyOps);
      }
    } else {
      log.auth('store clearing user');
      this.showAuthComponents = false;
      setTimeout(() => this.clearUserBits(), 1);
    }
    if (x) window.localStorage.setItem('authUserId', x.id);
    else window.localStorage.removeItem('authUserId');
  }

  logOut() {
    // we have to call the api to delete the cookie because it's httpOnly
    log.auth('store logOut');
    this.trackEvent(trackingEvents.bcLogout);
    return apiClient.sessionLogout().then(() => {
      this.setUser(false);
      amplitude.reset(); // clears amplitude ids
      window.location = clientAppPaths.afterLogout;
    }).catch((e) => {
      this.setGeneralError('_api_fail_')
    });
  }

  clearUserBits() {
    // the showAuthComponents boolean is used to conditionally render the
    // authenticated part of the application. on logout, it gets set false
    // first and then after a timeout the user bits are removed. that way mobx
    // doesn't cause errors by re-rendering authenticated components before the
    // top level branch component that woud remove them is re-rendered.
    this.user = false;
    this.userProfile = false;
    this.editingUserProfile = false;
    this.editingSlug = '';
    this.suggestedSlug = '';
  }

  validateSignupCode() {
    // DRY_47693 signup code logic
    return apiClient.validateSignupCode({ code: this.signupCode }).then(
      action(res => {
        this.signupCodeName = res.codeName;
        return res;
      })
    ).catch((e) => {
      this.setGeneralError('_api_fail_')
    });
  }

  // }}}

  ingestUpdatedUser(user) {
    this.user = user;
    this.userProfile = makeAutoObservable(
      new UserProfile({
        data: user.profile,
      }),
      {
        // validation is observed as a computed.struct because it returns a new
        // object every time it's read, but the structure and content of that
        // object do not change.
        validation: computed.struct,
      }
    );
    // note there is a possible buggy race condition in which the user makes an
    // subsequtn edit in a form field while the api call is in flight to save
    // prior edits, in which case, upon the response, we're going to blow
    // away those edits with whatever the api returned.  the flip side is, if
    // we didn't update this.editingUserProfile in order to preserve all user
    // edits even during in-flight requests, their forms could have old data if
    // they edited the value in another tab/context. i don't want to go to the
    // level of detail of tracking individual field states...
    this.editingUserProfile = makeAutoObservable(
      new UserProfile({
        data: user.profile,
        mobxMakeAutoObservable: makeAutoObservable,
        mobxComputed: computed,
      }),
      {
        validation: computed.struct,
      }
    );
    this.editingSlug = user.urlSlug || this.suggestedSlug || '';
    this.suggestedSlug = '';
    this.fetchSlugSuggestionIfNeeded();
    if (this.userProfile.getCompletedPromptIds().length > 0)
      this.clickedConfirmBasics = true;
  }

  saveProfileChanges() {
    if (!this.user)
      throw new Error('updateProfile called without having an auth user');
    // TODO: what if savingProfile is already true? it's mostly avoided
    // by disabling the save button while saving, but what else might
    // cause it?
    this.savingProfile = true;
    const wasIncomplete = !this.profileIsMinimallyComplete;
    return apiClient
      .postUserProfile({
        authUserId: this.user.id,
        userProfile: this.editingUserProfile,
      })
      .then(
        action(user => {
          this.ingestUpdatedUser(user);
          this.savingProfile = false;
          if (wasIncomplete && this.profileIsMinimallyComplete) {
            this.trackEvent(trackingEvents.caProfileBasicsAll);
          }
          return this.userProfile;
        })
      )
      .catch(
        action(e => {
          this.savingProfile = false;
          this.setGeneralError('_api_fail_')
          this.trackEvent(trackingEvents.apiError, {action:'profileSave'});
          throw e;
        })
      );
  }

  fetchSlugSuggestionIfNeeded() {
    if (this.user && !this.user.urlSlug && !this.user.unsolicited) {
      // they don't have a slug yet. fetch the current/best suggested slug from
      // the API when the user loads and/or they update their profile.
      apiClient
        .getUrlSlug({ authUserId: this.user.id, checkSlug: '' })
        .then(payload => {
          if (payload.suggestedSlug) {
            this.suggestedSlug = payload.suggestedSlug;
            this.editingSlug = payload.suggestedSlug;
          }
        }).catch(() => {});
    }
  }

  setEditingSlug(x) {
    this.editingSlug = x;
  }

  saveUrlSlug() {
    return apiClient
      .postUrlSlug({
        authUserId: this.user.id,
        slug: this.editingSlug,
      })
      .then(
        action(user => {
          this.ingestUpdatedUser(user);
          this.slugValidationError = false;
          return this.userProfile;
        })
      )
      .catch(e => {
        // slug unavailability or validation errors are common error cases
        // here.
        if (e.apiInfo?.responseWrapper?.status === 400) {
          this.slugValidationError = e.apiInfo.errorMsg;
        } else {
          this.slugValidationError = false;
          this.setGeneralError('_api_fail_')
        }
        throw e
      });
  }

  setUrlMetaProcessor(x) {
    this.urlMetaProcessor = x;
  }

  getUrlMeta(url) {
    return apiClient.getUrlMeta({
      authUserId: this.user.id,
      url,
      processor: this.urlMetaProcessor,
    });
  }

  rawUnfurl(url) {
    return apiClient.rawUnfurl({ authUserId: this.user.id, url });
  }

  getCurrentOnboardingStep() {
    // DRY_31808 onboarding steps
    // given the auth user's current profile status, where in the onboarding flow are they:
    // - needsName
    // - needsSlug
    // - needsAvatar
    // - needsConfirmBasics
    // - needsFirstPrompt
    // - needsSecondPrompt
    // - needsThirdPrompt
    // - done
    if (!this.user) return false;
    if (!this.userProfile.name) return 'needsName';
    if (!this.user.urlSlug) return 'needsSlug';
    if (!this.userProfile.avatarPublicID) return 'needsAvatar';
    if (!this.clickedConfirmBasics) return 'needsConfirmBasics';
    if (this.userProfile.getCompletedPromptIds().length === 0)
      return 'needsFirstPrompt';
    if (this.userProfile.getCompletedPromptIds().length === 1)
      return 'needsSecondPrompt';
    if (this.userProfile.getCompletedPromptIds().length === 2)
      return 'needsThirdPrompt';
    return 'done';
  }

  onboardingStepAsInt(step) {
    // DRY_31808 onboarding steps
    if (!step) return -1;
    return [
      'needsName',
      'needsSlug',
      'needsAvatar',
      'needsConfirmBasics',
      'needsFirstPrompt',
      'needsSecondPrompt',
      'needsThirdPrompt',
      'done',
    ].indexOf(step);
  }

  userIsAtOrBeyondStep(step) {
    // DRY_31808 onboarding steps
    return (
      this.onboardingStepAsInt(this.getCurrentOnboardingStep()) >=
      this.onboardingStepAsInt(step)
    );
  }

  setClickedConfirmBasics() {
    // also see ingestUpdatedUser which sets this true automatically if the
    // user has one or more prompts completed so the step logic doesn't revert
    // on a page reload.
    this.clickedConfirmBasics = true;
    // when the user clicks the button to collapse the basics editor, we also
    // then show a new bar below the header where they can reopen it. however
    // we want that bar to slide in AFTER the basics area has finished
    // animating, otherwise they're both moving at the same time, it's busy,
    // and you can't really tell the bar is newly presented.
    this.suppressBasicsBarOnTimer = true;
    this.trackEvent(trackingEvents.bcBasicsGetStarted);
    setTimeout(
      action(() => {
        this.suppressBasicsBarOnTimer = false;
      }),
      700
    );
  }

  getShouldShowFeedbackButton() {
    return !!(this.user && this.userProfile.getCompletedPromptIds().length > 0);
  }

  postWaitlist({ email, landing_page }) {
    return apiClient.postWaitlist({ email, landing_page });
  }

  // by initializing this in the module scope, it happens once. the order will
  // change on a page reload.
  generateRandomPromptOrder() {
    function shuffleArray(array) {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
      return array;
    }
    // we include the available prompts three times over and stack them. so when
    // the user goes through them all, they don't appear to repeat in the same
    // order.
    const allVisiblePrompts = userProfileConstants.ALL_PROMPTS.filter(
      x => x.visibility === 'all'
    );
    let bigList = [];
    for (let i = 0; i < 3; i++) {
      let shuffled = shuffleArray(allVisiblePrompts.slice());
      while (bigList.length && bigList[bigList.length - 1] === shuffled[0]) {
        // make sure it doesn't dupe at the end/start
        shuffleArray(shuffled);
      }
      bigList = bigList.concat(shuffled);
    }
    return bigList;
  }

  makeNextRandomPrompt() {
    if (!this.allPrompts3xInRandomOrder)
      this.allPrompts3xInRandomOrder = this.generateRandomPromptOrder();
    let completedIds = this.userProfile.getCompletedPromptIds();
    let remainingPrompts = this.allPrompts3xInRandomOrder.filter(
      x => completedIds.indexOf(x.id) < 0
    );
    // for dev/testing it's sometimes helpful to have just text prompts
    // remainingPrompts = remainingPrompts.filter(x => x.format === 'text')
    if (remainingPrompts.length === 0) {
      this.allPromptsComplete = true;
      return false;
    } else {
      this.allPromptsComplete = false;
      this.promptCounter = this.promptCounter + 1;
      const prompt =
        remainingPrompts[this.promptCounter % remainingPrompts.length];
      return prompt;
    }
  }

  checkPromptResponseIsValid(promptResponse) {
    if (!promptResponse) return false;
    const blankProfile = new UserProfile();
    blankProfile.setPromptResponse(promptResponse);
    return blankProfile.validation.valid;
  }

  trackEvent(evt, opts) {
    const key = evt.key;
    const fullEvent = JSON.parse(JSON.stringify(trackingEvents[key]));
    if (!fullEvent) {
      log.error(`tracked event is not in trackingEvents (${evt})`);
      return;
    }
    fullEvent.opts = { ...fullEvent.opts, ...opts };
    fullEvent.opts.isAuthed = this.user ? 'y' : 'n';
    log.tracking(fullEvent.eventName, fullEvent.opts.name, JSON.stringify(fullEvent));
    amplitude.track(fullEvent.eventName, fullEvent.opts);
  }

  setTrackedPageEvent(evt, opts) {
    const key = evt.key;
    const fullEvent = JSON.parse(JSON.stringify(trackingEvents[key]));
    if (!fullEvent) {
      log.error(`tracked event is not in trackingEvents (${evt})`);
      return;
    }
    fullEvent.opts = { ...fullEvent.opts, opts };
    if (!fullEvent.opts.name || fullEvent.eventName !== 'pageview') {
      log.error(
        `tracking a page event requires a pageview-like spec (${JSON.stringify(
          fullEvent
        )})`
      );
      return;
    }
    if (!isEqual(fullEvent, this.trackedPageEvent)) {
      this.trackEvent(evt, opts);
    }
    this.trackedPageEvent = fullEvent;
  }

  useTrackedPageEvent = (evt, opts) => {
    // react complains if i call useEffect as an object method. it has to be a
    // pure function. and you can't use 'this' in the dependency array.
    const self = this;
    return useEffect(() => {
      self.setTrackedPageEvent(evt, opts);
    }, [self, evt, opts]);
    // ((name, store) =>
    //   useEffect(() => {
    //     store.setTrackedPageName(name);
    //   }, [store, name]))(name, this);
  };

  setGeneralError = (errId) => {
    this.generalError = errId
  };

  clearGeneralError = () => {
    this.generalError = false;
  };

}

export default Store;
