import { useStore } from 'store';
import { useState, useEffect } from 'react';

const HealthCheck = () => {
  const [data, setData] = useState();
  const store = useStore();
  useEffect(() => {
    store.getHealthCheck().then(setData);
  }, [store]);
  if (!data) {
    return <p data-testid="healthCheckLoading">loading...</p>;
  }
  return (
    <div data-testid="healthCheck">
      <p>
        primes:{' '}
        <span data-testid="healthCheck-primes">{data.primes.join(',')}</span>
      </p>
      <p>
        dbReadsOk:{' '}
        <span data-testid="healthCheck-dbReadsOk">{data.dbReadsOk + ''}</span>
      </p>
      <p>
        tag_name:{' '}
        <span data-testid="healthCheck-tag_name">{data.tag_name + ''}</span>
      </p>
    </div>
  );
};

export { HealthCheck };
