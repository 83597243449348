import { observer } from 'mobx-react-lite';
import { useStore } from 'store';
import { OnboardingTip } from 'component/OnboardingTip';
import { Prompter } from 'component/prompts/Prompter';
import { PromptResponseList } from 'component/prompts/PromptResponseList';
import { H, P, Button } from 'component/uiLib';
import { trackingEvents } from 'shared-copied/constants.mjs';
import { Basics } from 'component/Basics';

const AuthHome = observer(() => {
  const store = useStore();
  store.useTrackedPageEvent(trackingEvents.pvHomeAuth);
  return (
    <div data-testid="authhome">
      <div style={{height:'24px'}}/>
      <Basics />
      <Prompter />
      <PromptResponseList />
    </div>
  );
});

export { AuthHome };
