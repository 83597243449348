/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import CircularProgress from '@mui/material/CircularProgress';

const CoverSpinner = ({ text, size, color, noBg, top }) => {
  // inside a relatively positioned element, it fills it with a white overlay
  // and a centered spinner
  let thickness = 3.6;
  size = size || 40;
  if (size <= 30) thickness = 5;
  if (size <= 20) thickness = 6;
  if (size <= 15) thickness = 7;
  if (typeof top === "number") top = top + 'px'
  return (
    <div
      css={css`
        position: absolute;
        top: ${top || '0px'};
        left: 0;
        bottom: 0;
        right: 0;
        background-color: ${noBg ? 'transparent' : 'var(--color-opaque-white)'};
        z-index: 100;
        display: grid;
        grid-template-columns: 100%;
        align-items: center;
        justify-items: center;
        text-align: center;
      `}
    >
      <div style={{ display: 'inline-block' }}>
        <CircularProgress
          size={size}
          thickness={thickness}
          sx={{ color: color || 'var(--color-link)' }}
        />
        {text && <p style={{ fontSize: '12px' }}>{text}</p>}
      </div>
    </div>
  );
};

export { CoverSpinner };
