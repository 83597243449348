/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Card, H, P } from 'component/uiLib';
import { observer } from 'mobx-react-lite';
import { useStore } from 'store';
import { ReactComponent as BannerTipBricks } from 'shared-copied/assets/images/icons/bannertip-bricks.svg';
import { ReactComponent as BannerTipBubbleCheck } from 'shared-copied/assets/images/icons/bannertip-bubblecheck.svg';
import { ReactComponent as BannerTipSimpleCheck } from 'shared-copied/assets/images/icons/bannertip-simplecheck.svg';
import { ReactComponent as BannerTipSmiley } from 'shared-copied/assets/images/icons/bannertip-smiley.svg';
import { ReactComponent as BannerTipThumbsUp } from 'shared-copied/assets/images/icons/bannertip-thumbsup.svg';

const TipHeading = ({children}) => <H h4 bold m0>{children}</H>

const OnboardingTip = observer(({
  inBasicsInitialEditor
}) => {
  const store = useStore();
  let step = store.getCurrentOnboardingStep();
  if (!step) return false;
  if (inBasicsInitialEditor) {
    // whats going on here is, when the user clicks the 'everything looks good'
    // button (store.setClickedConfirmBasics) the basics editor needs to collapse,
    // but the step of onboarding advances and changes the content in the tip
    // right as it collapses, which looks bad. so this logic locks the tip content
    // to the state when that button is visible, and then the whole area just
    // goes away, and another instance of this component is mounted with the
    // prompter layout.
    if (store.userIsAtOrBeyondStep('needsConfirmBasics')) {
      step = 'needsConfirmBasics';
    }
  }
  if (step === 'done') return false;
  const graphicCss = css`
    background-color: var(--color-subtle-bg-grey);
    width: 80px;
    height: 80px;
    margin-bottom: 16px;
  `;
  return (
    <Card nom css={css`
      border: 4px solid var(--color-charcoal);
      border-radius: 2px;
    `}>
      {(step === 'needsName' ||
        step === 'needsSlug' ||
        step === 'needsAvatar') && (
        <>
          <BannerTipSmiley css={graphicCss} />
          <TipHeading>Welcome to Grac3land</TipHeading>
          <P>
            Before you start building your Grac3land page, we need just a few pieces of info to finish setting up your account.
          </P>
          <P>
            {' '}
            {step === 'needsName' && (
              <strong>The first step is to enter your name.</strong>
            )}
            {step === 'needsSlug' && (
              <strong>Next, choose your Grac3land page’s link.</strong>
            )}
            {step === 'needsAvatar' && (
              <strong>Last but not least, upload a picture to serve as your avatar on Grac3land.</strong>
            )}
          </P>
        </>
      )}
      {step === 'needsConfirmBasics' && (
        <>
          <BannerTipSimpleCheck css={graphicCss} />
          <TipHeading>All set!</TipHeading>
          <P>
            Ready to get started with building your page? Click the <strong>“Get started”</strong> button.</P>
          <P>
            (Don’t worry, you can always come back and change these settings later.)
          </P>
        </>
      )}
      {step === 'needsFirstPrompt' && (
        <>
          <BannerTipBricks css={graphicCss} />
          <TipHeading>Start building your page</TipHeading>
          <P>
            Grac3land works by asking you interesting random questions. Each time you answer a question, you are adding to your page and building a more honest and authentic profile to share online.
          </P>
          <P>
            To get started, <strong>answer the question on this page</strong>. If you don’t like it, click “Give me another one!”
          </P>
        </>
      )}
      {step === 'needsSecondPrompt' && (
        <>
          <BannerTipThumbsUp css={graphicCss} />
          <TipHeading>Nice, you just answered your first question.</TipHeading>
          <P>
            Keep going! The more questions you answer, the more interesting your page will be.
          </P>
          <P><strong>
            (Please let us know what you think of Grac3land so far by <a className="novisited" href="https://survey.alchemer.com/s3/7138935/Graceland-MVP-feedback" target="_feedback">sharing your feedback</a>.)
          </strong></P>
        </>
      )}
      {step === 'needsThirdPrompt' && (
        <>
          <BannerTipBubbleCheck css={graphicCss} />
          <TipHeading>That’s two answers, now!</TipHeading>
          <P>
            Looks like you’ve got the hang of it. Keep going and answer as many questions as you like.
          </P>
          <P><strong>
            (Please let us know what you think of Grac3land so far by <a className="novisited" href="https://survey.alchemer.com/s3/7138935/Graceland-MVP-feedback" target="_feedback">sharing your feedback</a>.)
          </strong></P>
        </>
      )}
    </Card>
  );
});

export { OnboardingTip };
