/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { breakpoints, Button, H, Icon } from 'component/uiLib';
import { PageContentLimit } from 'component/uiLib/PageContentLimit';
import { LoginButton } from 'component/LogInSignUp/LoginButton';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

// TODO accessibility on the menu and drawer and on mobile

const Drawer = ({ linkButtons, open, onClose }) => {
  const [animState, setAnimState] = useState('off');
  const [timer, setTimer] = useState(false);
  useEffect(() => {
    if (timer) clearTimeout(timer);
    if (open) {
      if (animState === 'off') {
        setAnimState('slid_up');
      }
      if (animState === 'slid_up') {
        // TODO use proper animation package (css transition group or
        // whatever) as this timeout is not reliable.
        setTimeout(() => setAnimState('shown'), 30);
      }
      if (animState === 'shown') {
        // steady state
      }
    } else {
      if (animState === 'off') {
        // steady state
      }
      if (animState === 'slid_up') {
        setTimer(setTimeout(() => setAnimState('off'), 500));
      }
      if (animState === 'shown') {
        setAnimState('slid_up');
      }
    }
  }, [animState, open]);

  if (animState === 'off') return false;

  return (
    <>
      <div
        onClick={onClose}
        data-testid="mobileDrawerOverlay"
        css={css`
          position: fixed;
          z-index: 80;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: #000;
          opacity: ${animState === 'slid_up' ? '0' : '0.5'};
          transition: opacity 0.4s;
        `}
      />
      <div
        css={css`
          position: fixed;
          z-index: 100;
          top: ${animState === 'slid_up' ? '-400px' : '5vw'};
          left: 5vw;
          width: 90vw;
          background-color: white;
          border-radius: 4px;
          box-shadow: 0px 3px 30px #000;
          transition: top 0.4s ease-in;
        `}
        data-testid="mobileDrawer"
      >
        <div style={{ textAlign: 'right', padding: '16px' }}>
          <Button
            looksLikeLink
            linkColor="var(--color-charcoal)"
            aria-label="close"
            onClick={onClose}
            style={{ fontSize: '30px' }}
            data-testid="mobileDrawerCloseButton"
          >
            &#x2715;
          </Button>
        </div>
        <div
          data-testid="mobileDrawerButtons"
          css={css`
            display: flex;
            flex-direction: column;
            & > * {
              height: 40px;
              width: 80vw;
              line-height: 1;
            }
            align-items: center;
            text-align: center;
            gap: 16px;
            padding: 16px;
            box-sizing: border-box;
          `}
        >
          {linkButtons.map(b => b[1])}
        </div>
      </div>
    </>
  );
};

const StyledAppHeader = ({
  showFeedback,
  onFeedback,
  onLogout,
  onViewPage,
  viewPageUrl,
  loggedIn,
}) => {
  const isMobile = breakpoints.mq.down.useXs();
  const [mobileDrawerIsOpen, setMobileDrawerIsOpen] = useState(false);

  const linkButtons = [];

  // when logged out, the only header option is 'log in' and we don't want a
  // hamburger + drawer for just that.
  const useMobileLayout = isMobile && loggedIn

  if (viewPageUrl) {
    linkButtons.push([
      'view',
      <Button
        key="view page"
        looksLikeLink={!useMobileLayout}
        secondary
        href={viewPageUrl}
        onClick={onViewPage}
        target="_blank"
        data-testid="viewProfileButton"
      >
        View Your Page
      </Button>,
    ]);
  }

  if (showFeedback) {
    linkButtons.push([
      'feedback',
      <Button
        key="feedback"
        looksLikeLink={!useMobileLayout}
        secondary
        onClick={onFeedback}
        data-testid="feedbackBtn"
      >
        Feedback
      </Button>,
    ]);
  }

  if (loggedIn) {
    linkButtons.push([
      'logout',
      <Button
        key="logout"
        looksLikeLink={!useMobileLayout}
        secondary
        onClick={onLogout}
        data-testid="logoutBtn"
      >
        Logout
      </Button>,
    ]);
  } else {
    linkButtons.push([
      'login',
      <LoginButton
        text="Log in"
        data-testid="header-login-button"
        key="login"
        looksLikeLink={!useMobileLayout}
        overrideCodeCheckIfNoSignupCode
        onClick={() => {
          setMobileDrawerIsOpen(false);
        }}
      />,
    ]);
  }

  if (useMobileLayout) {
    return (
      <div
        id="app-header"
        css={css`
          border-bottom: 1px solid var(--color-charcoal);
        `}
      >
        <PageContentLimit mergedColumns>
          <div
            css={css`
              box-sizing: border-box;
              padding: 16px 0;
              display: flex;
              flex-direction: row;
              align-items: center;
            `}
          >
            <H h1 lh1
              style={{
                margin: 0,
                flexGrow: 1,
                fontSize: '14px',
              }}
            >
              <Link className="novisited" to="/">
                <img src="/shared/assets/images/logo/main-logo.svg" style={{height: '48px'}} alt="Grac3land logo" />
              </Link>
            </H>
            <Button
              looksLikeLink
              linkColor="var(--color-charcoal)"
              data-testid="openMobileDrawerButton"
              onClick={() => {
                setMobileDrawerIsOpen(true);
              }}
              style={{ lineHeight: '0.1' }}
            >
              {mobileDrawerIsOpen ? (
                <Icon.Close sx={{ fontSize: '30px' }} />
              ) : (
                <Icon.Menu sx={{ fontSize: '30px' }} />
              )}
            </Button>
            <Drawer
              open={mobileDrawerIsOpen}
              onClose={() => {
                setMobileDrawerIsOpen(false);
              }}
              linkButtons={linkButtons}
            />
          </div>
        </PageContentLimit>
      </div>
    );
  }

  return (
    <div
      id="app-header"
      css={css`
        border-bottom: 1px solid var(--color-charcoal);
      `}
    >
      <PageContentLimit mergedColumns>
        <div
          css={css`
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 24px;
            padding: 16px 0;
          `}
        >
          <H h1 lh1
            style={{
              margin: 0,
              flexGrow: 1,
              fontSize: '14px',
            }}
          >
            <Link to="/">
              <img src="/shared/assets/images/logo/main-logo.svg" style={{height: '48px'}} alt="Grac3land logo" />
            </Link>
          </H>
          {linkButtons.map(b => b[1])}
        </div>
      </PageContentLimit>
    </div>
  );
};

export { StyledAppHeader };
