// the central React Router switchboard

import clientAppPaths from 'clientAppPaths';
import { LazyLoadAdmin } from 'component/Admin';
import { HealthCheck } from 'component/HealthCheck';
import { Home } from 'component/Home';
import { LogInSignUp } from 'component/LogInSignUp';
import { Logout } from 'component/Logout';
import NotFound from 'component/NotFound';
import PageLayout from 'component/PageLayout';
import { Route, Routes } from 'react-router-dom';
import { useStore } from 'store';
import { AcceptableUse } from './AcceptableUse';
import { PrivacyPolicy } from './PrivacyPolicy';
import { TermsOfService } from './TermsOfService';

const AppRoutes = () => {
  const store = useStore();
  return (
    <Routes>
      <Route element={<PageLayout />}>
        <Route index element={<Home />} />
        <Route path="_health_check" element={<HealthCheck />} />
        <Route path="login" element={<LogInSignUp />} />
        <Route path="logout" element={<Logout />} />
        {store.user && store.user.isAdmin && (
          <Route path="admin" element={<LazyLoadAdmin />} />
        )}
        <Route path={clientAppPaths.privacyPolicyPath} element={<PrivacyPolicy />} />
        <Route path={clientAppPaths.termsPath} element={<TermsOfService />} />
        <Route path={clientAppPaths.acceptableUsePath} element={<AcceptableUse />} />
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
};

export { AppRoutes };

