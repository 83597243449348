/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { breakpoints } from 'component/uiLib';

const PageContentLimit = ({
  children,
  leftCol,
  rightCol,
  mergedColumns,
  textualPage,
}) => {
  // the rightcol content in this layout component is hidden on mobile.
  if (textualPage) mergedColumns = true;
  return (
    <div
      css={css`
        box-sizing: border-box;
        max-width: 1200px;
        margin: 0 auto;
        padding: 0 24px;
        ${breakpoints.mq.down.xs} {
          padding: 0 10px;
        }
      `}
    >
      <div
        css={css`
          display: grid;
          grid-template-columns: ${mergedColumns ? '1fr' : '1fr 2fr 1fr'};
          grid-gap: 24px;
          ${breakpoints.mq.down.sm} {
            grid-template-columns: 1fr;
            grid-auto-rows: auto;
            & > .rightcol {
              display: none;
            }
          }
        `}
      >
        {!mergedColumns && <div>{leftCol}</div>}
        <div
          css={css`
            max-width: ${textualPage ? '800px' : 'none'};
          `}
        >
          {children}
        </div>
        {!mergedColumns && <div className="rightcol">{rightCol}</div>}
      </div>
    </div>
  );
};

export { PageContentLimit };
