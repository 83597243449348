export const TERMS_OF_SERVICE_CONTENT = `
<h1>Terms of Use</h1>
December 14, 2022

<h3>1. Introduction</h3>
<p>Please read the terms of this entire document  (“Terms”) carefully because it explains your rights and responsibilities when you visit our website, or related feeds, social media, newsletters, source code repositories, and emails (“Websites”). By accessing the Website, you agree to be bound by these Terms.</p>
<p>Our Website is found at the domain grac3land.com. Our Website may connect you with links, apps or add-ons that are provided by other parties and are subject to separate Terms.</p>

<h3>2. Account Registration</h3>
<p>Our Website requires you to register for an account in order to access the features of the Website. You are responsible for all activities under your account.</p>
<p>Our Website requires you to create a username during registration. Your use of a username must comply with our <a href="/acceptable-use">Acceptable Use Policy</a>.</p>
<p>You must be at least 13 years old to create an account and use our service.</p>

<h3>3. Content Submissions</h3>
<p>You may contribute Content when interacting with our Website, including but not limited to  text, photos, and URLs (each a “Post”).</p>

<p>For all content you submit, you agree to the following:</p>

<ul>
	<li>You represent and warrant that your Post will comply with these Terms, tour Conditions of Use, and any additional terms that may govern your Post.</li>
	<li>You hereby grant us a nonexclusive, royalty-free, worldwide, sublicensable (to those we work with) license to display your Posts and in connection with any online or offline promotion of our service  on the Website.</li>
	<li>You acknowledge that your Posts may be accessible by other registered users of the applicable service or the public.</li>
	<li>You represent and warrant that you have the rights necessary to grant the rights granted herein, and further, that the uses contemplated under these Terms will not infringe the proprietary or intellectual property rights of any third party.</li>
	<li>You understand and agree that we reserve the right, at our discretion, to review, modify, or remove any Post that we deem objectionable or in violation of these Terms.</li>
</ul>

<h3>4. Communications and Events</h3>
<p>If you register for an account in connection with our Website, you may receive transactional emails from us in connection with your account (for example, legal, privacy, and security updates).</p>

<h3>5. Notice of Claimed Infringement</h3>
<p>To report claims of copyright or trademark infringement, please email <a href="mailto:contact@grace3land.com">contact@grace3land.com</a>.</p>

<h3>6. Term; Termination</h3>
<p>These Terms will continue to apply until ended by either you or us. You can choose to end them at any time for any reason by discontinuing your use of our Website and deleting your account.</p>
<p>We may suspend or terminate your access to our Website at any time for any reason, including, but not limited to, if we reasonably believe: (i) you have violated these Terms, our Acceptable Use Policy, or other relevant policy; (ii) you create risk or possible legal exposure for us; or (iii) if we decide not to offer the Website any more.</p>
<p>In all such cases, these Terms shall terminate, except that the following sections shall continue to apply: Indemnification, Disclaimer; Limitation of Liability, Miscellaneous.</p>

<h3>7. Indemnification</h3>
<p>You agree to defend, indemnify and hold harmless us, our contractors, contributors, licensors, and partners; and the respective directors, officers, employees and agents of the foregoing ("Indemnified Parties") from and against any and all third party claims and expenses, including attorneys' fees, arising out of or related to your use of our Website (including, but not limited to, from your Submissions or from your violation of any these Terms).</p>

<h3>8. Disclaimer; Limitation of Liability</h3>
<p>THE WEBSITE IS PROVIDED "AS IS" WITH ALL FAULTS. TO THE EXTENT PERMITTED BY LAW, WE AND THE INDEMNIFIED PARTIES HEREBY DISCLAIM ALL WARRANTIES, WHETHER EXPRESS OR IMPLIED, INCLUDING WITHOUT LIMITATION WARRANTIES THAT THE WEBSITE ARE FREE OF DEFECTS, MERCHANTABLE, FIT FOR A PARTICULAR PURPOSE, AND NON-INFRINGING. YOU BEAR THE ENTIRE RISK AS TO USING THE WEBSITE FOR YOUR PURPOSES AND AS TO THE QUALITY AND PERFORMANCE OF THE WEBSITE, INCLUDING WITHOUT LIMITATION THE RISK THAT YOUR HARDWARE, SOFTWARE, OR CONTENT IS DELETED OR CORRUPTED, THAT SOMEONE ELSE GAINS UNAUTHORIZED ACCESS TO YOUR INFORMATION, OR THAT ANOTHER USER MISUSES OR MISAPPROPRIATES YOUR SUBMISSION. THIS LIMITATION WILL APPLY NOTWITHSTANDING THE FAILURE OF ESSENTIAL PURPOSE OF ANY REMEDY. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF IMPLIED WARRANTIES, SO THIS DISCLAIMER MAY NOT APPLY TO YOU.</p>
<p>EXCEPT AS REQUIRED BY LAW, WE AND THE INDEMNIFIED PARTIES WILL NOT BE LIABLE FOR ANY INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR EXEMPLARY DAMAGES ARISING OUT OF OR IN ANY WAY RELATING TO THESE TERMS OR THE USE OF OR INABILITY TO USE THE WEBSITE, INCLUDING WITHOUT LIMITATION DIRECT AND INDIRECT DAMAGES FOR LOSS OF GOODWILL, WORK STOPPAGE, LOST PROFITS, LOSS OF DATA, AND COMPUTER FAILURE OR MALFUNCTION, EVEN IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGES AND REGARDLESS OF THE THEORY (CONTRACT, TORT, OR OTHERWISE) UPON WHICH SUCH CLAIM IS BASED. THE COLLECTIVE LIABILITY OF US AND THE INDEMNIFIED PARTIES UNDER THIS AGREEMENT WILL NOT EXCEED $500 (FIVE HUNDRED DOLLARS). SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF INCIDENTAL, CONSEQUENTIAL, OR SPECIAL DAMAGES, SO THIS EXCLUSION AND LIMITATION MAY NOT APPLY TO YOU.</p>

<h3>9. Modifications to these Terms</h3>
<p>We may update these Terms from time to time. The updated Terms will be posted online. If the changes are substantive, we will announce the update through our usual channels for such announcements such as blog posts, banners, emails, or forums. Your continued use of our Website after the effective date of such changes constitutes your acceptance of such changes. To make your review more convenient, we will post an effective date at the top of this page.</p>

<h3>10. Miscellaneous</h3>
<p>These Terms constitute the entire agreement between you and us concerning our Website and supersede any prior versions of these Terms. The Website and these Terms are governed by the laws of the state of California, U.S.A., excluding its conflict of law provisions. All claims and disputes arising out of the Website or these Terms shall be brought exclusively in the courts of Santa Clara County, California, and you consent to personal jurisdiction in those courts. If any portion of these Terms is held to be invalid or unenforceable, the remaining portions will remain in full force and effect. In the event of a conflict between a translated version of these Terms and the English language version, the English language version shall control. In the event of a conflict between these Terms and relevant additional terms, the additional terms shall control.</p>

<h3>11. Contact Us</h3>
<p>Grac3land</p>
<p><a href="mailto:contact@grace3land.com">contact@grace3land.com</a></p>
`