/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { CoverSpinner } from 'component/uiLib/CoverSpinner'

const Button = props => {
  const {
    children,
    id,
    loading,
    addCss,
    onClick,
    href,
    looksLikeLink,
    linkColor,
    style,
    disabled,
    primary,
    secondary,
    'data-testid': dataTestid,
    type,
    className,
  } = props;

  // note that for buttons, we do not (and i don't think we can) implement
  // visited link color differentiation. however, it's not desired because
  // buttons that look like links are really internal nav/actions and not
  // references to external sites where visited state matters.
  let bgColor = '#ccc';
  let hoverBgColor = '#aaa';
  let textColor = 'var(--color-charcoal)';
  let hoverTextColor = 'var(--color-charcoal)';
  let borderColor = '#ccc';
  let hoverBorderColor = '#aaa';
  let hoverTextDecoration = false;
  let padding = '12px 24px';
  if (primary) {
    bgColor = 'var(--color-charcoal)';
    hoverBgColor = 'var(--color-charcoal-light)';
    borderColor = bgColor;
    hoverBorderColor = hoverBgColor;
    textColor = 'var(--color-opaque-white)';
    hoverTextColor = 'var(--color-opaque-white)';
  }
  if (secondary) {
    bgColor = 'var(--color-opaque-white)';
    hoverBgColor = 'var(--color-opaque-white)';
    textColor = 'var(--color-charcoal)';
    hoverTextColor = 'var(--color-charcoal-light)';
    borderColor = 'var(--color-charcoal)';
    hoverBorderColor = 'var(--color-charcoal-light)';
  }
  if (disabled) {
    hoverTextColor = textColor;
    hoverBgColor = bgColor;
    hoverBorderColor = borderColor;
  }
  if (looksLikeLink) {
    bgColor = 'transparent';
    hoverBgColor = 'transparent';
    borderColor = 'transparent';
    hoverBorderColor = 'transparent';
    textColor = linkColor || 'var(--color-link)';
    hoverTextColor = linkColor || 'var(--color-link-hover)';
    hoverTextDecoration = 'var(--text-dec-link-hover)';
    padding = '0';
  } else {
    hoverTextDecoration = 'none';
  }
  let spinnerColor
  if (loading) {
    spinnerColor = textColor
    textColor = bgColor
    hoverBgColor = bgColor
    hoverTextColor = bgColor
    hoverBorderColor = borderColor
  }

  const ourClick = (e) => {
    if (!disabled && !loading && onClick) {
      onClick(e);
    }
  }

  if (href) {
    // if href is present, it IS a link, vs just looksLikeLink
    return (
      <a
        id={id}
        href={href}
        onClick={ourClick}
        target="_blank"
        rel="noopener noreferrer"
        style={style}
        data-testid={dataTestid}
        className={className}
        css={css`
          position: relative;
          margin: 0;
          box-sizing: border-box;
          padding: ${padding};
          border-radius: 5px;
          text-decoration: none;
          white-space: nowrap;

          &:visited {
            color: ${textColor};
          }
          &:active {
            color: ${textColor};
          }
          &:link {
            color: ${textColor};
          }

          background-color: ${bgColor};
          color: ${textColor};
          ${disabled && 'opacity: 0.5;'}
          border: 1px solid ${borderColor};
          &:hover {
            background-color: ${hoverBgColor};
            color: ${hoverTextColor};
            border: 1px solid ${hoverBorderColor};
            ${hoverTextDecoration ?  `text-decoration: ${hoverTextDecoration};` : ''}
          }
          ${addCss}
        `}
      >
        {loading && <CoverSpinner noBg color={spinnerColor} size={14} />}
        {children}
      </a>
    );
  }

  if (looksLikeLink) {
    return (
      <button
        id={id}
        type={type}
        onClick={ourClick}
        target="_blank"
        rel="noopener noreferrer"
        style={style}
        data-testid={dataTestid}
        className={className}
        css={css`
          position: relative;
          box-sizing: border-box;
          padding: ${padding};
          background: none;
          border: none;
          color: ${textColor};
          text-decoration: var(--text-dec-link);
          ${disabled && 'opacity: 0.5;'}
          &:hover {
            background: none;
            border: none;
            ${hoverTextDecoration ?  `text-decoration: ${hoverTextDecoration};` : ''}
            color: ${hoverTextColor};
          }
          ${addCss}
        `}
      >
        {loading && <CoverSpinner noBg color={spinnerColor} size={14} />}
        {children}
      </button>
    );
  }


  return (
    <button
      id={id}
      type={type}
      onClick={ourClick}
      style={style}
      disabled={disabled}
      data-testid={dataTestid}
      css={css`
        position: relative;
        box-sizing: border-box;
        padding: ${padding};
        white-space: nowrap;
        border-radius: 4px;
        background-color: ${bgColor};
        color: ${textColor};
        ${disabled && 'opacity: 0.5;'}
        border: 1px solid ${borderColor};
        &:hover {
          background-color: ${hoverBgColor};
          color: ${hoverTextColor};
          border: 1px solid ${hoverBorderColor};
          ${hoverTextDecoration ?  `text-decoration: ${hoverTextDecoration};` : ''}
        }
        ${addCss}
      `}
      className={className}
    >
      {loading && <CoverSpinner noBg color={spinnerColor} size={14} />}
      {children}
    </button>
  );
};

export { Button };
