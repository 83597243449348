/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { H, P, Button, Card } from 'component/uiLib';

const StyledBasicsTextField = ({
  field,
  value,
  onChange,
  placeholder,
  maxChars,
  validationErrTxt,
  isEditingAfterCompletion,
  'data-testid': dataTestid,
}) => {
  let classes = ['body-text-m'];
  const empty = (value || '').trim() === '';
  const tooLong = (value || '').length > maxChars;
  if (empty) classes.push('empty');
  if (empty && isEditingAfterCompletion)
    validationErrTxt = validationErrTxt || 'Required';
  if (tooLong) validationErrTxt = validationErrTxt || 'Too long';
  if (validationErrTxt) classes.push('invalid');
  if (field === 'slug') {
    return (
      <div
        className="our-forms"
        css={css`
          width: 100%;
          display: grid;
          grid-template-columns: auto 1fr;
          align-items: center;
          grid-template-areas:
            'urlbase input'
            'validation validation';
          grid-gap: 5px;
          & > * {
            margin: 0;
          }
        `}
      >
        <div style={{gridArea:'urlbase'}}>https://grac3land.com/</div>
        <input
          className={classes.join(' ')}
          type="text"
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          data-testid={dataTestid}
          style={{gridArea:'input',minWidth:'1px'}}
        />
        <div
          css={css`
            grid-area: validation;
            display: grid;
            grid-template-columns: 1fr auto;
            grid-gap: 5px;
            & > * {
              margin: 0;
            }
          `}
        >
          <P sm data-testid={validationErrTxt ? `${field}-validation-error` : ''} className="body-text-error">{validationErrTxt}</P>
          <P sm
            data-testid={tooLong ? `${field}-char-counter-too-long` : `${field}-char-counter`}
            className={
              (tooLong ? 'body-text-error' : 'body-text-light')
            }
          >
            {value.length}/{maxChars}
          </P>
        </div>
      </div>
    );
  }
  return (
    <div
      className="our-forms"
      css={css`
        display: grid;
        grid-auto-flow: row;
        grid-gap: 5px;
        & > * {
          margin: 0;
        }
      `}
    >
      <input
        className={classes.join(' ')}
        type="text"
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        data-testid={dataTestid}
      />
      <div
        css={css`
          display: grid;
          grid-template-columns: 1fr auto;
          grid-gap: 5px;
          & > * {
            margin: 0;
          }
        `}
      >
        <P sm data-testid={validationErrTxt ? `${field}-validation-error` : ''} className="body-text-error">{validationErrTxt}</P>
        <P sm
          data-testid={tooLong ? `${field}-char-counter-too-long` : `${field}-char-counter`}
          className={
            (tooLong ? 'body-text-error' : 'body-text-light')
          }
        >
          {value.length}/{maxChars}
        </P>
      </div>
    </div>
  );
};

export { StyledBasicsTextField };
