import { observer } from 'mobx-react-lite';
import { StyledLinkPreview } from 'component/uiLib/StyledLinkPreview';

const LinkPreview = observer(({ answer }) => {
  const urlMeta = answer.promptResponse.urlMeta || {};
  const url = answer.promptResponse.url;
  const promptId = answer.promptId;

  if (!url || url.trim().length === 0) return false;

  if (answer.fetchingUrlMeta) {
    return <StyledLinkPreview nom loading />;
  }

  if (answer.urlMetaFailed) {
    return <StyledLinkPreview nom failed />;
  }

  return (
    <StyledLinkPreview
      url={url}
      title={urlMeta.title}
      host={urlMeta.host}
      imageUrl={urlMeta.imageUrl}
      nom
    />
  );
});

export { LinkPreview };
