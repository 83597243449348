/** @jsxImportSource @emotion/react */
import { Button, Card } from 'component/uiLib';

import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useStore } from 'store';

import { AdvancedImage, lazyload } from '@cloudinary/react';
import { BasicsFormFieldName } from 'component/uiLib/BasicsFormFieldName';
import {
  getCloudinaryConfig,
  getCloudinaryTransform
} from 'shared-copied/cloudinaryConfig.mjs';
import { trackingEvents } from 'shared-copied/constants.mjs';
import { specialAssetIds } from 'shared-copied/cloudinaryConfig.mjs';

const Avatar = observer(() => {
  // because there is a lot of useEffect and callback dancing in this module to
  // create and interface with the cloudinary widget, we explicitly deference
  // store.userProfile and store.editingUserProfile as late as possible, rather
  // than creating shortcuts like 'const userProfile = store.userProfile',
  // because those constants do not update when the store replaces those
  // objects, such as on API call responses, when used inside closures like
  // callbacks and useEffect.
  const store = useStore();
  const [widget, setWidget] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const isComplete = () => !!store.userProfile.avatarPublicID;
  const afterBasicsConfirmed = store.userIsAtOrBeyondStep('needsFirstPrompt');
  const editNotAdd = isComplete();

  const cloudConfig = getCloudinaryConfig('avatar');

  const handleCloudinaryUpload = (error, result) => {
    if (!error && result && result.event === 'success') {
      // console.log('cloudinary result', result);
      store.editingUserProfile.avatarPublicID = result.info.public_id;
      // isComplete is a function so that we dont retain a closure reference to
      // an old value of completeness
      const wasIncomplete = !isComplete();
      setIsSaving(true);
      store
        .saveProfileChanges()
        .then(() => {
          setIsSaving(false);
          if (wasIncomplete) {
            store.trackEvent(trackingEvents.caProfileBasicsAvatar);
          } else {
            store.trackEvent(trackingEvents.edProfileBasicsAvatar);
          }
          console.log('avatar public id', store.userProfile.avatarPublicID);
        })
        .catch(e => {
          setIsSaving(false);
        });
    }
  };

  // const handleRemove = () => {
  //   store.editingUserProfile.avatarPublicID = '';
  //   store.saveProfileChanges();
  // }

  const handleUseDefault = () => {
    store.editingUserProfile.avatarPublicID = specialAssetIds.defaultAvatarID;
    store.saveProfileChanges();
  }

  const handleMockCloudinaryUpload = () => {
    store.editingUserProfile.avatarPublicID = specialAssetIds.mockAvatarID;
    store.saveProfileChanges();
  };

  const uploadImageWithCloudinary = () => {
    if (widget) {
      widget.open();
      return;
    }
    const avatarWidgetInstance = window.cloudinary.createUploadWidget(
      cloudConfig,
      handleCloudinaryUpload
    );
    avatarWidgetInstance.open();
    setWidget(avatarWidgetInstance);
  };

  const avatarPublicID = store.editingUserProfile.avatarPublicID;

  const isDefault = avatarPublicID === specialAssetIds.defaultAvatarID;

  const avatarEditing = (
    <div>
      <BasicsFormFieldName
        field="avatar"
        checkMark={isComplete() && !afterBasicsConfirmed}
      />
      {isDefault && <p style={{marginBottom:0}}>We’ve given you a default avatar for now</p>}
      {avatarPublicID && (
        <div>
          <AdvancedImage
            cldImg={getCloudinaryTransform('avatar', avatarPublicID, 150)}
            plugins={[lazyload()]}
            data-testid="avatarStaticImage"
            style={{ marginTop: '16px', width:'150px', height:'150px' }}
          />
        </div>
      )}
      {/* MH: TEMP: invisible element for cypress testing only*/}
      <Button
        type="submit"
        data-testid="avatarMockUploadButton"
        onClick={handleMockCloudinaryUpload}
        style={{ display: 'none' }}
      >
        Mock Upload
      </Button>
      <Button
        primary={!editNotAdd}
        secondary={editNotAdd}
        data-testid="avatarUploadButton"
        onClick={uploadImageWithCloudinary}
        style={{ marginTop: '16px', marginRight: '10px' }}
      >
        {(editNotAdd && !isDefault) ? 'Replace image' : 'Upload Image'}
      </Button>
      {!avatarPublicID && (
        <Button
          secondary
          data-testid="avatarUseDefaultButton"
          onClick={handleUseDefault}
          style={{ marginTop: '16px' }}
        >
          Skip
        </Button>)}
      {avatarPublicID && !isDefault && (
        <Button
          secondary
          data-testid="avatarRemoveButton"
          onClick={handleUseDefault}
          style={{ marginTop: '16px' }}
        >
          Remove
        </Button>)}
    </div>
  );

  return <Card data-testid="avatar-card">{avatarEditing}</Card>;
});

export { Avatar };
