import { useStore } from 'store';
import { trackingEvents } from 'shared-copied/constants.mjs';
import { H, P } from 'component/uiLib';
import { useEffect } from 'react';
import { PageContentLimit } from 'component/uiLib/PageContentLimit';

// TODO: we shouldn't log a user out with just a regular page load, it should
// require a button click. however, the public homepages have a logout link and
// they don't implement JS. so we link here. the http request itself doesn't
// log the user out, it's the JS that runs in the react app store, so CRSF /
// griefers can't log a user out, and page prefetching can't log a user out, so
// it's not so bad. i probably should implement an appserver /logout path that
// requires the CSRF token and implement that crsf token logic on the public
// page link as well with a little JS. eh.
function Logout() {
  const store = useStore();
  useEffect(() => {
    store.logOut(); // also navigates to home
  }, [store]);
  return (
    <div data-testid="logout" style={{ padding: '24px' }}>
      <PageContentLimit mergedColumns>
        <H h3>Logging out...</H>
      </PageContentLimit>
    </div>
  );
}

export { Logout };
