import { wrapFetch } from './apiCore.mjs';

const getHealthCheck = async () => {
  let payload = await wrapFetch({ action: 'health_check' });
  return payload;
};

const getMe = async (opts) => {
  opts = opts || {}
  const {asTestUser,signupCode} = opts
  const fetchOpts = {
    action: 'me',
    asTestUser,
  };
  if (signupCode) fetchOpts.queryParams = {signupCode}
  let payload = await wrapFetch(fetchOpts);
  return payload.user;
};

const postUserProfile = async ({
  userProfile,
  _testRawProfile,
  fullResetForTestUser,
  asTestUser,
}) => {
  let body = {}
  if (fullResetForTestUser) {
    // the api backend only accepts the string "confirm" for this value
    body.fullResetForTestUser = fullResetForTestUser
  } else {
    body.profile = _testRawProfile || userProfile.toPOJO()
  }
  let payload = await wrapFetch({
    action: 'profile',
    method: 'POST',
    body,
    asTestUser,
  });
  return payload.user;
};

const getUrlSlug = async ({ checkSlug, asTestUser }) => {
  const qp = {};
  if (typeof checkSlug === 'string') qp.checkSlug = checkSlug;
  let payload = await wrapFetch({
    action: 'url_slug',
    method: 'GET',
    queryParams: qp,
    asTestUser,
  });
  return payload;
};

const postUrlSlug = async ({ slug, asTestUser, fullResetForTestUser }) => {
  const body = {}
  if (fullResetForTestUser) {
    // the api backend only accepts the string "confirm" for this value
    body.fullResetForTestUser = fullResetForTestUser
  } else {
    body.slug = slug
  }
  let payload = await wrapFetch({
    action: 'url_slug',
    method: 'POST',
    body,
    asTestUser,
  });
  return payload.user;
};

const getUrlMeta = async ({ url, processor, asTestUser }) => {
  let payload = await wrapFetch({
    action: 'url_meta',
    method: 'GET',
    queryParams: {url, processor},
    asTestUser,
  });
  return payload;
};

const rawUnfurl = async ({ url }) => {
  let payload = await wrapFetch({
    action: 'raw_unfurl',
    method: 'GET',
    queryParams: {url},
  });
  return payload;
};

const postWaitlist = async ({ email, landing_page }) => {
  let payload = await wrapFetch({
    action: 'waitlist',
    method: 'POST',
    body: { email, landing_page },
  });
  return payload;
}

const getWaitlist = async ({ id }) => {
  let payload = await wrapFetch({
    action: 'waitlist',
    method: 'GET',
    queryParams: {id},
  });
  return payload;
}

const sessionLogin = async ({ idToken }) => {
  let payload = await wrapFetch({
    action: 'sessionLogin',
    method: 'POST',
    body: { idToken },
  });
  return payload;
}

const sessionLogout = async () => {
  let payload = await wrapFetch({
    action: 'sessionLogout',
    method: 'POST',
    body: { },
  });
  return payload;
};

const validateSignupCode = async ({code}) => {
  let payload = await wrapFetch({
    action: 'validateSignupCode',
    method: 'GET',
    queryParams: {code},
  });
  return payload;
};

const apiClient = {
  getHealthCheck,
  getMe,
  postUserProfile,
  getUrlSlug,
  postUrlSlug,
  getUrlMeta,
  rawUnfurl,
  postWaitlist,
  getWaitlist,
  sessionLogin,
  sessionLogout,
  validateSignupCode,
};

export default apiClient;
