/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { Button, Modal, breakpoints, H, P } from 'component/uiLib';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useStore } from 'store';
import { trackingEvents } from 'shared-copied/constants.mjs';

const minDelay = (ms, promiseToDelay) => {
  // given a duration and a promise, wraps the promise so that it waits for
  // that duration to elapse before resolving or rejecting
  const delay = new Promise(resolve => {
    setTimeout(() => resolve(), ms);
  });
  return new Promise((resolve, reject) => {
    Promise.all([delay, promiseToDelay])
      .then(resolve)
      .catch(e => {
        // promise.all will not wait for delay if promiseToDelay rejects sooner,
        // so explicitly wait and then pass on the rejected value
        delay.then(() => reject(e));
      });
  });
};

const WaitlistModal = () => {
  const store = useStore();
  const [open, setOpen] = useState(false);
  const [formError, setFormError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [validEmail, setValidEmail] = useState(false);
  const handleOpen = () => {
    setOpen(true);
    store.trackEvent(trackingEvents.bcWaitlist);
  };
  const handleClose = () => {
    setEmail('');
    setValidEmail(false);
    setFormError(false);
    setSuccess(false);
    setLoading(false);
    setOpen(false);
  };
  const handleEmailChange = e => {
    setEmail(e.target.value);
    setValidEmail(!!e.target.value.match(/\w+@\w+\.\w+/));
  };
  const handleSubmit = e => {
    e.preventDefault();
    e.stopPropagation();
    if (validEmail) {
      setLoading(true);
      minDelay(
        1000,
        store
          .postWaitlist({ email: email, landing_page: 'GRACELANDCOMROOT' })
          .then(() => store.trackEvent(trackingEvents.caJoinWaitlist))
      )
        .then(() => {
          setLoading(false);
          setSuccess(true);
          setFormError(false);
        })
        .catch(() => {
          setLoading(false);
          setSuccess(false);
          setFormError(true);
        });
    }
  };

  // const style = {
  //   position: 'absolute',
  //   top: '50%',
  //   left: '50%',
  //   transform: 'translate(-50%, -50%)',
  //   width: { xs: '96%', sm: '400px' },
  //   bgcolor: 'background.paper',
  //   border: '2px solid #000',
  //   borderRadius: 3,
  //   boxShadow: 24,
  //   p: 4,
  // };

  return (
    <>
      <Button
        primary
        onClick={handleOpen}
        variant="contained"
        data-testid="waitListButton"
      >
        Join waitlist
      </Button>
      <Modal
        title={success ? 'Thank you!' : 'Join the waitlist'}
        open={open}
        onClose={handleClose}
      >
        <div data-testid="modalBox">
          {success && (
            <>
              <P data-testid="successMsg">
                We’ll send you an email when registration opens up. Stay tuned.
              </P>
            </>
          )}
          {!success && (
            <>
              <P id="modal-modal-description">
                Grac3land beta test accounts are limited right now &mdash; join our waitlist and we’ll notify you when you’re in!
              </P>
              <div data-testid="waitlistEmailInputOuter">
                <form
                  id="email-form"
                  className="our-forms"
                  onSubmit={handleSubmit}
                  css={css`
                    display: flex;
                    flex-direction: row;
                    input {
                      flex-grow: 1;
                      font-size: 16px;
                      padding: 6px;
                    }
                    gap: 10px;
                    ${breakpoints.mq.down.xs} {
                      flex-direction: column;
                    }
                  `}
                >
                  <input
                    type="email"
                    required
                    id="email"
                    aria-label="email"
                    placeholder="Enter your e-mail"
                    value={email}
                    onChange={handleEmailChange}
                  />
                  <Button
                    primary
                    type="submit"
                    variant="contained"
                    data-testid="waitlistSubmit"
                    disabled={!validEmail}
                    loading={loading}
                  >
                    Submit
                  </Button>
                </form>
              </div>
              {formError && (
                <div id="form-error" style={{color:'var(--color-invalid-red)'}}>
                  <P>
                    There was an error processing the form &mdash; please reload the page and try again
                  </P>
                </div>
              )}
              <P>
                (We won’t share your email with anyone, and will only contact you to send your Grac3land invitation or to ask for your feedback.)
              </P>
            </>
          )}
        </div>
      </Modal>
    </>
  );
};

export { WaitlistModal };
