/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { AdvancedImage, lazyload } from '@cloudinary/react';
import { Cloudinary } from '@cloudinary/url-gen';
import { fill } from '@cloudinary/url-gen/actions/resize';
import { focusOn, autoGravity } from '@cloudinary/url-gen/qualifiers/gravity';
import { getCloudinaryTransform } from 'shared-copied/cloudinaryConfig.mjs';
import { LightBoxWrapper } from 'component/uiLib/LightBoxWrapper';

const StyledAnswerImage = ({ cloudinaryAssetId }) => {
  if (!cloudinaryAssetId) return false;

  return (
    <div css={css`
      & img {
        width: 100%;
      }
      line-height: 1; // fixes extra space after image
    `}>
      <LightBoxWrapper
        highResSrc={getCloudinaryTransform('prompter', cloudinaryAssetId, 'original').toURL()}>
      <AdvancedImage
        cldImg={getCloudinaryTransform('prompter', cloudinaryAssetId)}
        plugins={[lazyload()]}
      />
      </LightBoxWrapper>
    </div>
  );
};

export { StyledAnswerImage };
