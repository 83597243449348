/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import MuiModal from '@mui/material/Modal';
import { Button, H, breakpoints } from 'component/uiLib';

const Modal = props => {
  const {
    title,
    hideTitle,
    noPad,
    children,
    open,
    onClose,
    ...otherprops
  } = props;
  return (
    <MuiModal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-title"
      // disableScrollLock prevents mui from changing the dimensions of the
      // body element and causing a weird reflow of elements in the modal
      disableScrollLock
      {...otherprops}
    >
      <div
        css={css`
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 400px;
          ${breakpoints.mq.down.xs} {
            width: 96%;
          }
          background-color: var(--color-opaque-white);
          border-radius: 2px;
          box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
        `}
      >
        <div
          css={css`
            padding: 16px;
            display: grid;
            grid-template-columns: 1fr auto;
            border-bottom: 1px solid var(--color-border-light);
            margin-bottom: ${noPad ? '0' : '16px'};
            align-items: start;
            grid-gap: 16px;
            & #modal-close {
              font-size: 30px;
              line-height: 10px;
              margin-top: 5px;
            }
          `}
        >
          <H h4 bold m0
            id="modal-title"
            style={{ visibility: hideTitle ? 'hidden' : 'visible' }}
            css={css`
              ${breakpoints.mq.down.xs} {
                font-size: 16px;
              }
            `}
          >
            <strong>{title}</strong>
          </H>
          <Button
            looksLikeLink
            id="modal-close"
            data-testid="modalClose"
            aria-label="close"
            onClick={onClose}
          >
            &#x2715;
          </Button>
        </div>
        <div style={{ padding: noPad ? '0' : '0 16px 16px' }}>{children}</div>
      </div>
    </MuiModal>
  );
};

export { Modal };
