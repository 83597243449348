import { makeAutoObservable, toJS } from 'mobx';
import log from 'shared-copied/log.mjs';
import { debounce } from 'lodash-es';
import { trackingEvents } from 'shared-copied/constants.mjs';
import { constants as userProfileConstants } from 'shared-copied/UserProfile/constants.mjs';
import { specialAssetIds } from 'shared-copied/cloudinaryConfig.mjs';
import { getCloudinaryConfig } from 'shared-copied/cloudinaryConfig.mjs';

class AnswerStore {
  promptResponse = false;
  prompt = false;
  promptId = false;
  store = false;
  fetchingUrlMeta = false;
  urlMetaFailed = false;
  lastUrlFetch = false;
  isEdit = false;
  widget = false;

  // these two have UI states. clicking the button adds a form element,
  // clicking it again removes that element.
  textEnabled = false;
  urlEnabled = false;

  constructor(store) {
    this.store = store;
    makeAutoObservable(
      this,
      {
        store: false,
        fetchUrlMetaAndUpdateDebounced: false,
      },
      {
        autoBind: true, // for callbacks and debounced fn binding
      }
    );
    this.fetchUrlMetaAndUpdateDebounced = debounce(this._fetchUrlMeta, 1000, {
      // leading=true because i assume they'll copy+paste the url in one event, so
      // start right away
      leading: true,
    });
  }

  reset() {
    this.initPromptId(this.promptId);
  }

  initPromptId(promptId) {
    const userProfile = this.store.userProfile;
    this.prompt = userProfile.promptById(promptId);
    this.promptId = this.prompt?.id;
    this.textEnabled = false;
    this.urlEnabled = false;
    this.lastUrlFetch = false;
    this.urlMetaFailed = false;
    this.fetchingUrlMeta = false;
    const existingResponse = userProfile.promptResponseById(promptId);
    if (existingResponse) {
      this.isEdit = true;
      // if initial promptResponse has urlMeta, we don't need to refetch it
      // this has to be done before setPromptResponseWithSideEffects
      if (existingResponse.url && existingResponse.urlMeta) {
        this.lastUrlFetch = existingResponse.url;
      }
      this.setPromptResponseWithSideEffects(existingResponse);
    } else {
      this.isEdit = false;
      // initialize an empty prompt response
      this.setPromptResponseWithSideEffects({
        id: this.prompt?.id, // ? guard because all prompts might be complete and prompt is false
        version: 2,
        createdAtMillis: new Date().getTime(),
        updatedAtMillis: new Date().getTime(),
      });
    }
  }

  advanceToNextRandomPrompt() {
    const newPrompt = this.store.makeNextRandomPrompt();
    this.initPromptId(newPrompt.id);
  }

  setPromptResponseWithSideEffects(r) {
    this.promptResponse = r;
    if ((r.text || '').trim().length > 0) {
      this.textEnabled = true;
    }
    if ((r.url || '').trim().length > 0) {
      this.urlEnabled = true;
    }
    this.maybeFetchUrl();
  }

  enableText() {
    this.promptResponse.text = '';
    this.textEnabled = true;
  }

  enableUrl() {
    this.promptResponse.url = '';
    this.promptResponse.urlMeta = undefined;
    this.urlEnabled = true;
  }

  removeText() {
    this.promptResponse.text = '';
    this.textEnabled = false;
  }

  removeUrl() {
    this.promptResponse.url = '';
    this.promptResponse.urlMeta = undefined;
    this.urlEnabled = false;
  }

  removeImage() {
    this.promptResponse.cloudinaryAssetId = undefined;
  }

  setPromptResponseFieldWithSideEffects(field, value) {
    this.promptResponse[field] = value;
    this.maybeFetchUrl();
  }

  maybeFetchUrl() {
    if (!this.promptResponse) return;
    const url = this.promptResponse.url || '';
    if (url.trim()) {
      if (this.lastUrlFetch !== url.trim()) {
        this.lastUrlFetch = url;
        this.fetchUrlMetaAndUpdateDebounced();
      }
    }
  }

  save(opts) {
    opts = opts || {};
    this.store.editingUserProfile.setPromptResponse(toJS(this.promptResponse));
    return this.store.saveProfileChanges().then(() => {
      const completedPromptCount = this.store.userProfile.getCompletedPromptIds()
        .length;
      if (this.isEdit) {
        this.store.trackEvent(trackingEvents.edProfilePrompt, {
          id: this.promptId,
          completedPromptCount,
        });
      } else {
        this.store.trackEvent(trackingEvents.caProfilePrompt, {
          id: this.promptId,
          // for a caProfilePrompt event where completedPromptCount=1 that can
          // be inferred to be the user's first completion of a prompt.
          // although it will refire if they delete all their prompts and start
          // over.
          // likewise completedPromptCount=2 means it's the user's second
          // completed prompt, modulo the fact they possible did more before
          // but deleted them.
          completedPromptCount,
        });
      }
      // this.initPromptId(this.prompt.id) // reset form values
      // if (opts.advance) this.advanceToNextRandomPrompt();
    });
  }

  deleteAnswer() {
    this.store.editingUserProfile.deletePromptResponseById(this.prompt.id);
    this.store.saveProfileChanges().then(() => {
      const completedPromptCount = this.store.userProfile.getCompletedPromptIds()
        .length;
      this.store.trackEvent(trackingEvents.caDeletePrompt, {
        id: this.promptId,
        completedPromptCount,
      });
    });
  }

  get isValid() {
    return this.store.checkPromptResponseIsValid(toJS(this.promptResponse));
  }

  get allPromptsComplete() {
    return this.store.allPromptsComplete;
  }

  get hasAnyUGC() {
    // DRY_96792 prompt validation logic
    // used to decide if a confirmation modal should pop before discarding a prompt response.
    const r = this.promptResponse || {};
    const checks = [r.text, r.url, r.cloudinaryAssetId];
    for (let i = 0; i < checks.length; i++) {
      if ((checks[i] || '').trim().length > 0) return true;
    }
    return false;
  }

  get hasImage() {
    return (this.promptResponse.cloudinaryAssetId || '').trim().length > 0;
  }

  _fetchUrlMeta() {
    this.fetchingUrlMeta = true;
    this.urlMetaFailed = false;
    this.store
      .getUrlMeta(this.promptResponse.url)
      .then(meta => {
        this.fetchingUrlMeta = false;
        this.urlMetaFailed = false;
        // not using setPromptResponseWithSideEffects
        this.promptResponse.urlMeta = meta;
      })
      .catch(e => {
        log.error('meta fetch error', e);
        this.fetchingUrlMeta = false;
        this.urlMetaFailed = true;
        // not using setPromptResponseWithSideEffects
        this.promptResponse.urlMeta = undefined;
      });
  }

  handleCloudinaryUpload(error, result) {
    if (!error && result && result.event === 'success') {
      // console.log('cloudinary result', result);
      this.setPromptResponseFieldWithSideEffects(
        'cloudinaryAssetId',
        result.info.public_id
      );
    }
  }

  handleMockCloudinaryUpload() {
    const mockAvatarID = 'cypress-avatar-dont-delete';
    this.setPromptResponseFieldWithSideEffects(
      'cloudinaryAssetId',
      mockAvatarID
    );
  }

  uploadImageWithCloudinary() {
    if (this.widget) {
      this.widget.open();
      return;
    }
    const cloudConfig = getCloudinaryConfig('prompter', {
      promptId: this.promptId,
    });
    this.widget = window.cloudinary.createUploadWidget(
      cloudConfig,
      this.handleCloudinaryUpload
    );
    this.widget.open();
  }
}

class DemoAnswerStore {
  promptResponse = false;
  prompt = false;
  promptId = false;
  fetchingUrlMeta = false;
  isEdit = false;

  constructor(useCase) {
    if (useCase === '_song_unanswered_') {
      this.promptId = 'promptTextFavSong';
    }
    if (useCase === '_song_answered_') {
      this.promptId = 'promptTextFavSong';
    }
    if (useCase === '_lunch_answered_') {
      this.promptId = 'promptImageMemorableRestaurantMeal';
    }
    if (useCase === '_webpage_answered_') {
      this.promptId = 'promptLinkMakesMeThink';
    }
    this.prompt = userProfileConstants.ALL_PROMPTS.find(
      x => x.id === this.promptId
    );
    this.promptResponse = {
      id: this.promptId,
      version: 2,
      createdAtMillis: new Date().getTime(),
      updatedAtMillis: new Date().getTime(),
    };
    if (useCase === '_song_answered_') {
      this.promptResponse.text =
        'After playing Splatoon 3, Deep Cut - Anarchy Rainbow is such an amazing song. It gets me groovin and the beats are so sick!';
    }
    if (useCase === '_lunch_answered_') {
      this.promptResponse.cloudinaryAssetId =
        specialAssetIds.homepageLunchPrompt;
      this.promptResponse.text = 'I can banh mis all day every day!';
    }
    if (useCase === '_webpage_answered_') {
      this.promptResponse.url = 'https://www.16personalities.com/';
      this.promptResponse.urlMeta = {
        imageUrl: `https://res.cloudinary.com/dbpulyvbq/image/upload/c_scale,h_150/v1/${specialAssetIds.homepageLinkPreviewImage}?_a=AJAJZWI0`,
        host: '16personalities.com',
        title:
          'Free personality test, type descriptions, relationship and career advice | 16Personalities',
      };
      this.promptResponse.text =
        'When this website first came out, I remember sending this to all my friends in high school - it’s always so much fun to do personality tests and learn about each other lol!';
    }
  }

  advanceToNextRandomPrompt() {}

  setPromptResponseWithSideEffects(r) {}

  setPromptResponseFieldWithSideEffects(field, value) {}

  save(opts) {}

  deleteAnswer() {}

  get isValid() {
    return false;
  }

  get allPromptsComplete() {
    return false;
  }

  get hasAnyUGC() {
    return false;
  }

  get hasImage() {
    return (this.promptResponse.cloudinaryAssetId || '').trim().length > 0;
  }


}

export { AnswerStore, DemoAnswerStore };
