/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

const GriddedRegion = ({ children, padding }) => {
  return (
    <div
      css={css`
        padding: ${padding || '36px 0'};
        background: #fff url(/shared/assets/images/bg/grid_tile_1x.png) repeat;

        only screen and (-webkit-min-device-pixel-ratio: 2),
        only screen and (min--moz-device-pixel-ratio: 2),
        only screen and (min-device-pixel-ratio: 2),
        only screen and (-webkit-min-device-pixel-ratio: 2),
        only screen and (min-resolution: 192dpi),
        only screen and (min-resolution: 2dppx) {
          background: #fff url(/shared/assets/images/bg/grid_tile_2x.png) repeat;
        }
      `}
    >
      {children}
    </div>
  );
};

export { GriddedRegion };
