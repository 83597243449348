/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { LoginButton } from 'component/LogInSignUp/LoginButton';
import { PromptsHero } from 'component/LogInSignUp/PromptsHero';
import { breakpoints, H, P } from 'component/uiLib';
import { GriddedRegion } from 'component/uiLib/GriddedRegion';
import { PageContentLimit } from 'component/uiLib/PageContentLimit';
import { WaitlistModal } from 'component/WaitlistModal';

const StyledLogInSignUp = ({ signupCode }) => {
  return (
    <>
      <div data-testid="loginPage">
        <GriddedRegion>
          <div style={{ textAlign: 'center', padding: '16px 24px' }}>
            <H
              h1
              bold
              mv={24}
              mh="auto"
              css={css`
                max-width: 800px;
                ${breakpoints.mq.down.sm} {
                  font-size: 300%;
                }
                ${breakpoints.mq.down.xs} {
                  font-size: 200%;
                }
              `}
            >
              {signupCode && <span>Be yourself. For real.</span>}
              {!signupCode && <span>Be yourself. For real.</span>}
            </H>
            <P xl mv={36}>
              {signupCode && (
                <span>
                  You’ve been invited to join the beta test of Grac3land &mdash;
                  try it for yourself today.
                </span>
              )}
              {!signupCode && (
                <span>
                  Invitations are rolling out soon for our beta test &mdash;
                  reserve your spot now!
                </span>
              )}
            </P>
            {signupCode && <LoginButton primary text="Get your own page!" />}
            {!signupCode && <WaitlistModal />}
          </div>
          <PromptsHero />
        </GriddedRegion>
        <div style={{ textAlign: 'center', padding: '16px 24px' }}>
          <H h3 m="24px 0 0 0">
            What is <strong>Grac3land</strong>?
          </H>
          <P xl style={{ maxWidth: '520px'}}  m='24px auto'>
            Grac3land helps you build a special kind of profile page, one that
            lets people get to know the <em>real</em> you.
          </P>
        </div>
        <GriddedRegion padding="36px 16px">
          <PageContentLimit mergedColumns>
            <H h3 m="0 0 24px 0" style={{ textAlign: 'center' }}>
              How it works
            </H>
            <div
              css={css`
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                grid-template-rows: auto auto auto;
                grid-auto-flow: column;
                grid-gap: 16px;
                h4 {
                  text-align: center;
                  margin: 0;
                  line-height: 1.2;
                }
                .ico {
                  text-align: center;
                }
                p {
                  text-align: justify;
                }
                ${breakpoints.mq.down.sm} {
                  grid-template-columns: 1fr;
                  grid-template-rows: repeat(9, auto);
                  p {
                    text-align: center;
                  }
                }
              `}
            >
              <div className="ico">
                <img
                  src="/shared/assets/images/icons/monitor-blocks.svg"
                  alt="an iconographic drawing of computer monitor showing a software application interface"
                />
              </div>
              <H h4>Thought-provoking questions</H>
              <P md>
                Grac3land asks you fun questions that make it easy to share
                interesting or surprising truths about yourself.
              </P>
              <div className="ico">
                <img
                  src="/shared/assets/images/icons/activity-collage.svg"
                  alt="an iconographic drawing of various kinds of media in a collage"
                />
              </div>
              <H h4>Many ways to answer</H>
              <P md>
                You can answer these questions by uploading an image, sharing a
                link, or even just writing a few words.
              </P>
              <div className="ico">
                <img
                  src="/shared/assets/images/icons/classic-person.svg"
                  alt="an iconographic drawing of person on a computer screen"
                />
              </div>
              <H h4>
                Show off who you <em>really</em> are
              </H>
              <P md>
                We use your answers to magically build you your own
                one-of-a-kind profile page. Share it with anyone you like,
                anywhere you want.
              </P>
            </div>
          </PageContentLimit>
        </GriddedRegion>
        <div style={{ textAlign: 'center', padding: '16px 24px' }}>
          <H h3 m="24px 0 0 0">
            {!signupCode && <span>Sign up for our waitlist</span>}
            {signupCode && <span>Try it now</span>}
          </H>
          <P md style={{ maxWidth: '520px' }} m='24px auto' >
            {!signupCode && (
              <span>
                Invitations are rolling out soon for our beta test &mdash;
                reserve your spot now!
              </span>
            )}
            {signupCode && (
              <span>
                You’ve been invited to join the beta test of Grac3land &mdash;
                try it for yourself today.
              </span>
            )}
          </P>
          {signupCode && <LoginButton primary text="Get your own page!" />}
          {!signupCode && <WaitlistModal />}
        </div>
      </div>
    </>
  );
};

export { StyledLogInSignUp };
