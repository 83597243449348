/** @jsxImportSource @emotion/react */
import { Outlet } from 'react-router-dom';
import { css } from '@emotion/react';
// import { breakpoints }  from 'component/uiLib'
import { AppFooter } from 'component/AppFooter';
import { AppHeader } from 'component/AppHeader';
import { GeneralErrorModal } from 'component/GeneralErrorModal';
import { LoginGlobalOverlay} from 'component/LogInSignUp/LoginGlobalOverlay';

function PageLayout() {
  return (
    <div
      css={css`
        background-color: var(--color-welcoming-eggshell);
        box-sizing: border-box;
        width: 100%;
        min-height: 100vh;
        display: grid;
        grid-template-rows: auto 1fr auto;
      `}
    >
      <GeneralErrorModal />
      <LoginGlobalOverlay />
      <AppHeader />
      <main>
        <Outlet />
      </main>
      <AppFooter />
    </div>
  );
}

export default PageLayout;
