import { useStore } from 'store';
import { observer } from 'mobx-react-lite';
import { AnswerForm } from './AnswerForm';
import { useState } from 'react';
import { AnswerStore } from './AnswerStore';
import { trackingEvents } from 'shared-copied/constants.mjs';
import { StyledPrompter } from 'component/uiLib/StyledPrompter';
import { PageContentLimit } from 'component/uiLib/PageContentLimit';
import { GriddedRegion } from 'component/uiLib/GriddedRegion';
import { OnboardingTip } from 'component/OnboardingTip';

const Prompter = observer(() => {
  const store = useStore();
  const [answer] = useState(() => {
    const answer = new AnswerStore(store);
    answer.advanceToNextRandomPrompt();
    return answer;
  });

  const advance = () => {
    store.trackEvent(trackingEvents.bcGetDifferentPrompt, {
      skippedPromptId: answer.promptId,
    });
    answer.advanceToNextRandomPrompt();
  };

  const save = () => {
    // return the promise so the StyledPrompter can wait for the result
    // return answer.save({ advance: true });
    return answer.save();
  };

  const isValid = answer.isValid;

  if (!store.userIsAtOrBeyondStep('needsFirstPrompt')) {
    return false;
  }

  return (
    <GriddedRegion>
      <PageContentLimit leftCol={<OnboardingTip />}>
        <StyledPrompter
          promptHeading={answer.prompt.questionHeading}
          formFields={<AnswerForm answer={answer} displayContext="prompter" />}
          onSave={save}
          onAdvance={advance}
          isValid={isValid}
          allPromptsComplete={answer.allPromptsComplete}
          hasAnyUGC={answer.hasAnyUGC}
        />
      </PageContentLimit>
    </GriddedRegion>
  );
});

export { Prompter };
