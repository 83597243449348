/** @jsxImportSource @emotion/react */
import { useStore } from 'store';
import { observer } from 'mobx-react-lite';
import { AnswerForm } from './AnswerForm';
import { useState } from 'react';
import { AnswerStore } from './AnswerStore';
import {
  StyledPromptResponse,
  StyledEditingPromptResponse,
} from 'component/uiLib/StyledPromptResponse';
import { CoverSpinner } from 'component/uiLib/CoverSpinner';

const PromptResponse = observer(({ promptId }) => {
  const store = useStore();
  const [isEditing, setIsEditing] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [answer] = useState(() => {
    const answer = new AnswerStore(store);
    answer.initPromptId(promptId);
    return answer;
  });
  const startEditing = () => {
    setIsEditing(true);
  };
  const save = () => {
    setIsSaving(true);
    answer.save().then(() => {
      setIsSaving(false);
      setIsEditing(false);
    });
  };
  const cancel = () => {
    answer.reset();
    setIsEditing(false);
  };
  const deletePrompt = () => {
    setIsDeleting(true);
    answer.deleteAnswer();
  };

  if (!isEditing) {
    return (
      <div style={{ position: 'relative' }}>
        {isDeleting && <CoverSpinner text="deleting..." />}
        <StyledPromptResponse
          promptHeading={
            answer.prompt.answeredHeading || answer.prompt.questionHeading
          }
          text={answer.promptResponse.text}
          url={answer.promptResponse.url}
          urlMeta={answer.promptResponse.urlMeta}
          cloudinaryAssetId={answer.promptResponse.cloudinaryAssetId}
          onDelete={deletePrompt}
          onEdit={startEditing}
        />
      </div>
    );
  } else {
    return (
      <StyledEditingPromptResponse
        promptHeading={answer.prompt.questionHeading}
        formFields={<AnswerForm answer={answer} displayContext="answer" />}
        onSave={save}
        onCancel={cancel}
        isValid={answer.isValid}
      />
    );
  }
});

export { PromptResponse };
