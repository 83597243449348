/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import MuiModal from '@mui/material/Modal';
import { breakpoints, H, Button } from 'component/uiLib';
import { useState } from 'react';
import Fade from '@mui/material/Fade';

const LightBoxWrapper = ({ children, highResSrc, maxHeight }) => {
  const [open, setOpen] = useState(false);
  const [animating, setAnimating] = useState(false);
  const onClick = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  return (
    <>
      <MuiModal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-title"
        // disableScrollLock prevents mui from changing the dimensions of the
        // body element and causing a weird reflow of elements in the modal
        disableScrollLock
      >
        <Fade in={open}>
          <div
            css={css`
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              width: 100vw;
              height: 100vh;
            `}
          >
            <div
              css={css`
                visibility: hidden;
                height: 0px;
                border: 1px solid transparent;
              `}
            >
              <H h4 id="modal-title">
                Full-screen image detail modal
              </H>
              <Button
                looksLikeLink
                id="modal-close"
                linkColor="var(--color-charcoal)"
                data-testid="modalClose"
                aria-label="close"
                onClick={onClose}
              >
                close
              </Button>
            </div>
            <div
              onClick={onClose}
              css={css`
                display: grid;
                grid-template-columns: 1fr;
                box-sizing: border-box;
                padding: 16px;
                width: 100vw;
                height: 100vh;
                align-items: center;
                justify-content: center;
              `}
            >
              <div
                css={css`
                  display: flex;
                  max-height: ${maxHeight || 'none'};
                `}
              >
                <img
                  src={highResSrc}
                  onClick={onClose}
                  css={css`
                    width: 100%;
                    object-fit: contain;
                  `}
                />
              </div>
            </div>
          </div>
        </Fade>
      </MuiModal>
      <div
        css={css`
          cursor: pointer;
        `}
        onClick={onClick}
      >
        {children}
      </div>
    </>
  );
};

export { LightBoxWrapper };
