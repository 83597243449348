import { H, P } from 'component/uiLib';
import { PageContentLimit } from 'component/uiLib/PageContentLimit';
import { observer } from 'mobx-react-lite';
import { useStore } from 'store';
import { PromptResponse } from './PromptResponse';

const PromptResponseList = observer(() => {
  const store = useStore();
  const userProfile = store.userProfile;
  const ids = userProfile.getCompletedPromptIds();
  if (!store.userIsAtOrBeyondStep('needsFirstPrompt')) {
    return false;
  }
  return (
    <PageContentLimit>
      <div style={{textAlign:'center'}}>
      <H h3 mv={36}>
        <strong>
          Answered Questions
        </strong>
      </H>
      {ids.length === 0 && <P>You haven’t answered any questions yet!</P>}
      </div>
      {ids.map(id => (
        <PromptResponse key={id} promptId={id} />
      ))}
      <div style={{height:'150px'}}/>
    </PageContentLimit>
  );
});

export { PromptResponseList };
