/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import clientAppPaths from 'clientAppPaths';
import { breakpoints } from 'component/uiLib';
import { PageContentLimit } from 'component/uiLib/PageContentLimit';
import { Link } from 'react-router-dom';

export const AppFooter = () => {
  const { privacyPolicyPath, termsPath, acceptableUsePath } = clientAppPaths;
  return (
    <footer
      id="app-footer"
      className="body-text-s"
      css={css`
        border-top: 1px solid var(--color-charcoal);
        padding: 16px;
        margin-top: 24px;
        text-align: center;
        & ul {
          margin: 0;
          padding: 0;
          list-style-type: none;
        }
        & li {
          display: inline-block;
          margin: 0 10px;
          padding: 0;
        }
      `}
    >
      <PageContentLimit mergedColumns>
        <ul>
          <li>
            <Link
              className="novisited"
              to={`/${termsPath}`}
            >
              Terms of Service
            </Link>
          </li>
          <li>
            <Link
              className="novisited"
              to={`/${privacyPolicyPath}`}
            >
              Privacy Policy
            </Link>
          </li>
          <li>
            <Link className="novisited" to={`/${acceptableUsePath}`}>
              Acceptable Use
            </Link>
          </li>
        </ul>
      </PageContentLimit>
    </footer>
  );
};
