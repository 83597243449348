export const ACCEPTABLE_USE_CONTENT = `
<h1>Acceptable Use Policy</h1>

<h3>Conditions of Use</h3>
December 14, 2022

<p>You may not use any of our services to:</p>
<ul>
	<li>Do anything illegal or otherwise violate applicable law,</li>
	<li>Threaten, harass, or violate the privacy rights of others; send unsolicited communications; or intercept, monitor, or modify communications not intended for you,</li>
	<li>Harm users such as by using viruses, spyware or malware, worms, trojan horses, time bombs or any other such malicious codes or instructions,
	<li>Deceive, mislead, defraud, phish, or commit or attempt to commit identity theft,</li>
	<li>Engage in or promote illegal gambling,</li>
	<li>Degrade, intimidate, incite violence against, or encourage prejudicial action against someone or a group based on age, gender, race, ethnicity, national origin, religion, sexual orientation, disability, geographic location or other protected category,</li>
	<li>Exploit or harm children,</li>
	<li>Sell, purchase, or advertise illegal or controlled products or services,
	<li>Upload, download, transmit, display, or grant access to content that includes graphic depictions of sexuality or violence,</li>
	<li>Collect or harvest personally identifiable information without permission. This includes, but is not limited to, account names and email addresses,</li>
	<li>Engage in any activity that interferes with or disrupts our services or products (or the servers and networks which are connected to our services),
	<li>Violate the copyright, trademark, patent, or other intellectual property rights of others,</li>
	<li>Violate any person’s rights of privacy or publicity.</li>
</ul>
<p>You may not use our service in a way that violates the <a href="/terms">Terms of Use</a> or Conditions of Use.</p>

<p>These are only examples. You should not consider this a complete list, and we may update the list from time to time. We reserve the right to remove any content or suspend any users that it deems in violation of these conditions.</p>
`