/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { H, P, Button, Card, Icon, breakpoints } from 'component/uiLib';
import { StyledLinkPreview } from 'component/uiLib/StyledLinkPreview';
import { StyledAnswerImage } from 'component/uiLib/StyledAnswerImage';
import { ConfirmationModal } from 'component/uiLib/ConfirmationModal';
import { useState } from 'react';
import { LinkifiedUserBlurb } from 'component/uiLib/LinkifiedUserBlurb';

const UserSays = ({ children }) => (
  <div
    className="body-text-m"
    css={css`
      padding: 16px;
      background-color: var(--color-calm-blue);
      margin: 16px 0;
      white-space: pre-line; /* pre-line preserves user newlines in the text */
      display: grid;
      grid-auto-flow: row;
      grid-gap: 16px;
    `}
  >
    {children}
  </div>
);

const StyledPromptResponse = ({
  promptHeading,
  text,
  url,
  urlMeta,
  cloudinaryAssetId,
  onDelete,
  onEdit,
  disableBreakpoints,
}) => {
  urlMeta = urlMeta || {};

  const [confirm, setConfirm] = useState(false);
  const confirmDelete = () => {
    setConfirm(true);
  };
  const onYes = () => {
    onDelete();
    setConfirm(false);
  };
  const onNo = () => {
    setConfirm(false);
  };

  const hasText = !!((text || '').trim())
  const hasUrl = !!((url || '').trim())
  const hasImage = !!((cloudinaryAssetId || '').trim())

  return (
    <Card>
      <ConfirmationModal
        open={confirm}
        onYes={onYes}
        onNo={onNo}
        title="Are you sure you want to delete this answer?"
        body="This action cannot be undone."
        yesText="Yes, delete it"
        noText="Cancel"
      />
      <H h4 bold m='0 0 16px 0'>
        <strong>{promptHeading}</strong>
      </H>
      <UserSays>
        {hasImage && (
          <StyledAnswerImage cloudinaryAssetId={cloudinaryAssetId} />
        )}
        {hasUrl && (
          <StyledLinkPreview
            nom
            url={url}
            title={urlMeta.title}
            host={urlMeta.host}
            imageUrl={urlMeta.imageUrl}
            disableBreakpoints={disableBreakpoints}
          />
        )}
        {hasText && <div><LinkifiedUserBlurb blurb={text} /></div>}
      </UserSays>
      <div
        css={css`
          display: grid;
          grid-template-columns: auto auto;
          grid-gap: 16px;
        `}
      >
        <Button secondary onClick={onEdit} style={{ margin: '0 auto 0 0' }}>
          Edit
        </Button>
        <Button
          looksLikeLink
          onClick={confirmDelete}
          style={{ margin: '0 0 0 auto' }}
        >
          <Icon.DeleteForeverOutlined
            sx={{
              verticalAlign: 'middle',
              position: 'relative',
              top: '-2px',
            }}
          />
          Delete
        </Button>
      </div>
    </Card>
  );
};

const StyledEditingPromptResponse = ({
  promptHeading,
  formFields,
  onSave,
  onCancel,
  isValid,
}) => {
  const [saving,setIsSaving] = useState(false)
  const ourSave = () => {
    setIsSaving(true)
    onSave()
  }
  return (
    <Card>
      <H h4 bold m='0 0 24px 0'>
        <strong>{promptHeading}</strong>
      </H>
      <div
        css={css`
          display: grid;
          grid-gap: 24px;
          grid-auto-flow: row;
          padding-bottom: 24px;
          margin-bottom: 24px;
          border-bottom: 1px solid var(--color-border-light);
        `}
      >
        {formFields}
      </div>
      <Button
        loading={saving}
        primary
        onClick={ourSave}
        disabled={!isValid}
        style={{ marginRight: '16px' }}
      >
        Update
      </Button>
      <Button secondary onClick={onCancel}>
        Cancel
      </Button>
    </Card>
  );
};

export { StyledPromptResponse, StyledEditingPromptResponse };
