import parse from 'html-react-parser';
import { PRIVACY_POLICY_CONTENT } from './copy';
import { trackingEvents } from 'shared-copied/constants.mjs';
import { useStore } from 'store';
import { PageContentLimit } from 'component/uiLib/PageContentLimit';

export const PrivacyPolicy = () => {
  const store = useStore();
  store.useTrackedPageEvent(trackingEvents.pvPrivacyPolicy);
  return (
    <PageContentLimit textualPage>
      {parse(PRIVACY_POLICY_CONTENT)}
    </PageContentLimit>
  );
};
