// the rules for validating user profile data from a user content point of
// view. this drives form validation in the client as well as backend
// validation of the same rules since clients are not trusted.

import normalizeUrl from 'normalize-url';
import { constants } from './constants.mjs';

function getParsedUrl({ url, strict }) {
  let parsedUrl;
  try {
    let processedUrl = url;
    if (!strict) {
      // this might throw an error but is more lenient than URL lib
      processedUrl = normalizeUrl(url, constants.normalizeUrlConfig);
    }
    // this can also throw an error and is strict
    parsedUrl = new URL(processedUrl);
    if (parsedUrl.protocol !== 'https:' && parsedUrl.protocol !== 'http:') {
      throw new Error('url scheme not allowed');
    }
    if (parsedUrl.username || parsedUrl.password) {
      throw new Error('auth on urls not allowed');
    }
  } catch (e) {
    // any error from normalizeUrl, new URL(), or our own rules
    return false;
  }
  return parsedUrl;
}

export function getValidationErrors({ data }) {
  let errors = {};

  if ((data.name || '').length > constants.MAX_CHARS.names) {
    errors.name = {
      errorMsg: `name is limited to at most ${constants.MAX_CHARS.names} characters`,
      // errorConst is used for e2e tests
      errorConst: 'ERR_NAME_TOO_LONG',
    };
  }

  if (
    (data.avatarPublicID || '').length > constants.MAX_CHARS.cloudinaryAssetIds
  ) {
    errors.avatarPublicID = {
      errorMsg: `avatar id is too long`,
      errorConst: 'ERR_AVATAR_ID_TOO_LONG',
    };
  }

  if (data.promptResponses) {
    // DRY_96792 prompt validation logic
    for (let resp of data.promptResponses) {
      const promptId = resp.id || 'missing prompt id';
      const registerError = (errorMsg, errorConst) => {
        errors.promptResponses = errors.promptResponses || {};
        errors.promptResponses[promptId] = {
          errorMsg,
          errorConst,
        };
      };
      if (resp.version !== 2) {
        registerError(`prompt v2 required`, 'ERR_INVALID_PROMPT_FORMAT');
      }
      if (constants.ALL_PROMPTS.findIndex(x => x.id === promptId) < 0) {
        registerError(`unrecognized prompt id`, 'ERR_UNKNOWN_PROMPT_ID');
      } else {
        const url = resp.url || '';
        if (url.length > constants.MAX_CHARS.urls) {
          registerError(
            `prompt response urls are limited to ${constants.MAX_CHARS.urls} characters`,
            'ERR_PROMPT_URL_TOO_LONG'
          );
        }
        if (url.trim().length && !getParsedUrl({ url, strict: true })) {
          registerError(
            'prompt response invalid url',
            'ERR_PROMPT_URL_INVALID'
          );
        }
        const urlMeta = resp.urlMeta;
        if (urlMeta) {
          for (let urlMetaProp in urlMeta) {
            const val = urlMeta[urlMetaProp] || '';
            if (val.length > constants.MAX_CHARS.urlMetaProps) {
              registerError(
                `prompt response invalid url metadata`,
                'ERR_PROMPT_URL_META_INVALID'
              );
            }
            if (urlMetaProp === 'imageUrl') {
              // go the distance and specifically validate urls as such for
              // safety/security reasons.
              if (val.length && !getParsedUrl({ url: val, strict: true })) {
                registerError(
                  `prompt response invalid imageUrl in metadata`,
                  'ERR_PROMPT_URL_META_IMAGE_URL_INVALID'
                );
              }
            }
          }
        }
        const text = resp.text || '';
        if (text.length > constants.MAX_CHARS.userBlurbs) {
          registerError(
            `prompt response text is limited to ${constants.MAX_CHARS.userBlurbs} characters`,
            'ERR_PROMPT_TEXT_TOO_LONG'
          );
        }
        const cloudinaryAssetId = resp.cloudinaryAssetId || '';
        if (cloudinaryAssetId.length > constants.MAX_CHARS.cloudinaryAssetIds) {
          registerError(
            'invalid prompt response cloudinary image asset id',
            'ERR_PROMPT_CLOUDINARY_ASSET_ID'
          );
        }
        // must have text, url, or image defined
        if (!text.trim().length && !url.trim().length && !cloudinaryAssetId.trim().length) {
          registerError(
            'at least some text, image, or link content is required',
            'ERR_PROMPT_EMPTY'
          );
        }
      }
    }
  }

  const valid = Object.keys(errors).length === 0;

  return {
    valid,
    errors,
  };
}
