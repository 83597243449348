// this component implements the various top-level wrappers we need around the
// core application routes

import { StoreReadinessWrapper, StoreRouter, StoreWrapper } from 'store';
import { FullPageSpinner } from 'component/uiLib/FullPageSpinner';
import { AppRoutes } from 'component/AppRoutes';

function App() {
  return (
    <div className="App" data-testid="approot">
      <StoreWrapper>
        <StoreReadinessWrapper ifNotReady={<FullPageSpinner />}>
          <StoreRouter>
            <AppRoutes />
          </StoreRouter>
        </StoreReadinessWrapper>
      </StoreWrapper>
    </div>
  );
}

export default App;
