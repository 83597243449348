/** @jsxImportSource @emotion/react */
import { Button, Card, P } from 'component/uiLib';
import { BasicsFormFieldName } from 'component/uiLib/BasicsFormFieldName';
import { StyledBasicsTextField } from 'component/uiLib/StyledBasicsTextField';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { trackingEvents } from 'shared-copied/constants.mjs';
import { constants as userProfileConstants } from 'shared-copied/UserProfile/constants.mjs';
import { useStore } from 'store';

const Name = observer(() => {
  const store = useStore();
  const userProfile = store.userProfile;
  const editingUserProfile = store.editingUserProfile;
  const [isEditingProfile, setIsEditingProfile] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const validation = editingUserProfile.validation;
  const isComplete = () => (userProfile.name || '').trim().length > 0;
  const isEmpty = (editingUserProfile.name || '').trim().length === 0;
  const maxChars = userProfileConstants.MAX_CHARS.names;
  const afterBasicsConfirmed = store.userIsAtOrBeyondStep('needsFirstPrompt');

  const editNotAdd = isComplete();

  const handleSaveName = e => {
    e.preventDefault();
    // isComplete is a function so that we dont retain a closure reference to
    // an old value of completeness
    const wasIncomplete = !isComplete();
    setIsSaving(true);
    store
      .saveProfileChanges()
      .then(() => {
        setIsSaving(false);
        if (wasIncomplete) {
          store.trackEvent(trackingEvents.caProfileBasicsName);
        } else {
          store.trackEvent(trackingEvents.edProfileBasicsName);
        }
        setIsEditingProfile(false);
      })
      .catch(() => {
        setIsSaving(false);
        // the end to end cypress auth tests rely on a failure here for csrf
        // token checks.
      });
  };

  const handleChangeName = e => {
    editingUserProfile.name = e.target.value;
  };

  const handleCancel = () => setIsEditingProfile(false);

  const NameStatic = (
    <div>
      <BasicsFormFieldName
        field="name"
        checkMark={isComplete() && !afterBasicsConfirmed}
      />
      {userProfile.name && (
        <P md mb={0}>
          {userProfile.name}
        </P>
      )}
      {/* why do i need margin bottom 0 on the p tag, shouldn't it collapse with the buttons top margin?? */}
      <Button
        primary={!editNotAdd}
        secondary={editNotAdd}
        onClick={() => {
          setIsEditingProfile(true);
        }}
        data-testid="profileNameEditButton"
        style={{ marginTop: '16px' }}
      >
        {editNotAdd ? 'Edit' : 'Add'}
      </Button>
    </div>
  );

  const NameEditing = (
    <div>
      <BasicsFormFieldName field="name" checkMark={false} />
      <P md>
        What do you want to be called on your Grac3land page? You can use your
        real name, a pseudoname or handle, or whatever you want.
      </P>
      <form onSubmit={handleSaveName}>
        <StyledBasicsTextField
          field="name"
          value={editingUserProfile.name}
          onChange={handleChangeName}
          maxChars={maxChars}
          data-testid="profileNameInput"
          validationErrTxt={validation?.errors?.name?.errorMsg}
          isEditingAfterCompletion={isComplete()}
        />
        <Button
          primary
          type="submit"
          data-testid="profileNameSaveButton"
          disabled={store.savingProfile || !validation.valid || isEmpty}
          style={{ margin: '16px 10px 0 0' }}
          loading={isSaving}
        >
          Save
        </Button>
        <Button secondary data-testid="nameCancelButton" onClick={handleCancel}>
          Cancel
        </Button>
      </form>
    </div>
  );

  return (
    <Card data-testid="name-card">
      {isEditingProfile ? NameEditing : NameStatic}
    </Card>
  );
});

export { Name };
