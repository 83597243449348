/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Modal } from './Modal';
import { Button, H, P, breakpoints } from 'component/uiLib';

const ConfirmationModal = ({
  open,
  title,
  body,
  children,
  yesText,
  noText,
  onYes,
  onNo,
}) => {
  return (
    <Modal title={title} open={open} onClose={onNo} disableScrollLock>
      {body && <P id="confirm-modal-body">{body}</P>}
      {!body && (
        <div id="confirm-modal-body" style={{ marginBottom: '16px' }}>
          {children}
        </div>
      )}
      <div
        css={css`
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-gap: 16px;
          ${breakpoints.mq.down.xs} {
            grid-template-columns: 1fr;
            grid-template-rows: auto;
          }
        `}
      >
        {yesText && (
          <Button primary onClick={onYes}>
            {yesText}
          </Button>
        )}
        {noText && (
          <Button secondary onClick={onNo}>
            {noText}
          </Button>
        )}
      </div>
    </Modal>
  );
};

export { ConfirmationModal };
