/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { observer } from 'mobx-react-lite';
import { AnswerField } from './AnswerField';
import { LinkPreview } from './LinkPreview';
import { StyledAnswerImage } from 'component/uiLib/StyledAnswerImage';
import { Button, breakpoints } from 'component/uiLib';
import { Player } from '@lottiefiles/react-lottie-player';
import { ReactComponent as LinkIcon } from 'shared-copied/assets/images/icons/answer-link-button.svg';
import { ReactComponent as TextIcon } from 'shared-copied/assets/images/icons/answer-text-button.svg';
import { ReactComponent as ImageIcon } from 'shared-copied/assets/images/icons/answer-image-button.svg';

const AnswerForm = observer(({ answer, displayContext }) => {
  const promptResponse = answer.promptResponse;
  const handleChange = (field, value) => {
    answer.setPromptResponseFieldWithSideEffects(field, value);
  };
  const enableText = () => answer.enableText();
  const enableUrl = () => answer.enableUrl();
  const removeText = () => answer.removeText();
  const removeUrl = () => answer.removeUrl();

  let textField = false;
  if (answer.textEnabled) {
    textField = (
      <AnswerField
        isEditing={answer.isEdit}
        field="text"
        value={promptResponse.text || ''}
        onChange={handleChange}
      />
    );
  }

  let urlField = false;
  if (answer.urlEnabled) {
    urlField = (
      <>
        <AnswerField
          isEditing={answer.isEdit}
          field="url"
          value={promptResponse.url || ''}
          onChange={handleChange}
        />
        <LinkPreview answer={answer} />
      </>
    );
  }

  let imageField = false;
  if (answer.hasImage) {
    imageField = (
      <div style={{textAlign:'right'}}>
        <StyledAnswerImage
          cloudinaryAssetId={promptResponse.cloudinaryAssetId}
        />
        <Button
          looksLikeLink
          onClick={answer.uploadImageWithCloudinary}
        >
          Replace image
        </Button>
      </div>
    );
  }

  const icoStyle = {verticalAlign:'top',marginRight:'8px'}

  return (
    <>
      <div
        css={css`
          display: grid;
          grid-gap: 10px;
          grid-template-columns: 1fr 1fr 1fr;
          ${breakpoints.mq.down.xs} {
            grid-template-columns: 1fr;
            grid-template-rows: auto auto auto;
          }
          & button {
            font-size: 14px;
            padding: 8px;
          // & svg { display: none; }
        `}
      >
      {answer.hasImage ? (
        <Button
          secondary
          onClick={() => handleChange('cloudinaryAssetId', undefined)}
        >
          <ImageIcon style={icoStyle} />
          Remove image
        </Button>
      ) : (
        <>
          <Button secondary onClick={answer.uploadImageWithCloudinary}>
            <ImageIcon style={icoStyle} />
            Add image
          </Button>
          <Button
            style={{ display: 'none' }}
            data-testid="mockUploadButton"
            onClick={answer.handleMockCloudinaryUpload}
          >
            mock upload image
          </Button>
        </>
      )}
      {answer.urlEnabled ? (
        <Button secondary onClick={removeUrl}>
          <LinkIcon style={icoStyle} />
          Remove link
        </Button>
      ) : (
        <Button secondary onClick={enableUrl}>
          <LinkIcon style={icoStyle} />
          Add link
        </Button>
      )}
      {answer.textEnabled ? (
        <Button secondary onClick={removeText}>
          <TextIcon style={icoStyle} />
          Remove text
        </Button>
      ) : (
        <Button secondary onClick={enableText}>
          <TextIcon style={icoStyle} />
          Add text
        </Button>
      )}
      </div>
      {imageField}
      {urlField}
      {textField}
    </>
  );
});

export { AnswerForm };
