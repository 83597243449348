/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { FullPageSpinner, Button, H, P } from 'component/uiLib';
import { observer } from 'mobx-react-lite';
import { useEffect, useRef, useState } from 'react';
import log from 'shared-copied/log.mjs';
import { useStore } from 'store';
import { trackingEvents } from 'shared-copied/constants.mjs';
import { StyledFirebaseAuth } from 'component/Home/StyledFirebaseAuth';
import { ConfirmationModal } from 'component/uiLib/ConfirmationModal';
import { Modal } from 'component/uiLib/Modal';

const LoginButton = observer(({
  overrideCodeCheck,
  overrideCodeCheckIfNoSignupCode,
  primary,
  secondary,
  text,
  looksLikeLink,
  'data-testid': dataTestid,
  onClick,
}) => {
  const store = useStore();
  if (!store.signupCode) {
    overrideCodeCheck = overrideCodeCheck || overrideCodeCheckIfNoSignupCode
  }
  const handleClick = e => {
    store.launchGlobalLoginOverlay(overrideCodeCheck)
    onClick && onClick(e)
  }
  return (
      <Button
        onClick={handleClick}
        primary={primary}
        secondary={secondary || !primary}
        looksLikeLink={looksLikeLink}
        loading={store.loginButtonsSpinning}
        data-testid={dataTestid || "loginButton"}
      >
        {text}
      </Button>
  );
});

export { LoginButton };
